import styled from "styled-components";

export const UserSettingsWrapper = styled.div`
  background-color: ${(props) => props.theme.background.light};
  box-sizing: border-box;
  min-height: 76px;
  padding: 24px 26px;
`;

export const UserSettingsForm = styled.div`
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 24px;
`;

export const AlertBanner = styled.div`
  margin-top: ${(props) => props.theme.spacings.spacing5};
`;

export const SuccessAlertBannerLabel = styled.div`
  align-items: center;
  display: flex;

  svg {
    color: ${(props) => props.theme.semanticColors.success.dark};
  }
`;

export const SuccessAlertBannerTitle = styled.span`
  color: ${(props) => props.theme.background.black};
  display: inline-block;
  font-weight: 600;
  margin-left: ${(props) => props.theme.spacings.spacing4};
`;

export const SuccessAlertBannerMessage = styled.span`
  color: ${(props) => props.theme.background.black};
  display: inline-block;
  margin-left: ${(props) => props.theme.spacings.spacing6};
`;

export const FailureAlertBannerLabel = styled.div`
  align-items: center;
  display: flex;

  svg {
    color: ${(props) => props.theme.semanticColors.success.dark};
  }
`;

export const FailureAlertBannerTitle = styled.span`
  color: ${(props) => props.theme.background.black};
  display: inline-block;
  font-weight: 600;
  margin-left: ${(props) => props.theme.spacings.spacing4};
`;

export const FailureAlertBannerMessage = styled.span`
  color: ${(props) => props.theme.background.black};
  display: inline-block;
  margin-left: ${(props) => props.theme.spacings.spacing6};
`;
