import React, {
  useReducer,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import {
  reducer as usersReducer,
  initialState as usersInitialState,
  fetchUsers,
} from "./UserSettings.users";
import {
  reducer as userSettingsReducer,
  initialState as userSettingsInitialState,
  fetchUser as fetchUserSettings,
  FetchUserActionType,
} from "../../pages/EditUserPage.user";
import { GetUserResponse } from "new-design/utils/backendTypes";
import {
  Typeahead,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  Alert,
  AlertType,
  ThemeType,
} from "@kettu/ui-components";
import { useFormattedMessage } from "../../utils/useFormattedMessage.hook";
import {
  UserSettingsWrapper,
  UserSettingsForm,
  AlertBanner,
  SuccessAlertBannerLabel,
  SuccessAlertBannerTitle,
  SuccessAlertBannerMessage,
  FailureAlertBannerLabel,
  FailureAlertBannerTitle,
  FailureAlertBannerMessage,
} from "./styled";
import { useTheme } from "styled-components";

export type UserSettingsProps = {
  onUserSettingsCallback: (userSettings: GetUserResponse) => void;
};

export const UserSettings = (props: UserSettingsProps) => {
  const theme = useTheme() as ThemeType;

  const findUserPlaceholder = useFormattedMessage(
    "user-settings.find-user-placeholder"
  );
  const importSettingsFromAnotherUserText = useFormattedMessage(
    "user-settings.import-settings-from-another-user"
  );
  const typeaheadLoadingText = useFormattedMessage(
    "user-settings.typeahead-loading-text"
  );
  const buttonLabel = useFormattedMessage("user-settings.button-label");
  const importSucceededStatus = useFormattedMessage(
    "user-settings.import-succeeded-status"
  );
  const importSucceededNotification = useFormattedMessage(
    "user-settings.import-succeeded-notification"
  );
  const importFailedStatus = useFormattedMessage(
    "user-settings.import-failed-status"
  );
  const importFailedNotification = useFormattedMessage(
    "user-settings.import-failed-notification",
    { br: <br /> }
  );

  const [usersState, usersDispatcher] = useReducer(
    usersReducer,
    usersInitialState
  );

  const handleUserTypeaheadChange = useCallback(
    (searchQuery: any) =>
      fetchUsers({ dispatch: usersDispatcher, query: searchQuery }),
    []
  );

  const usersSearchTypeaheadOptions = useMemo(
    () =>
      usersState.users.map((user) => {
        const name = `${user.userPersonalInformation.firstName} ${user.userPersonalInformation.lastName}`;
        return {
          label: name,
          value: user.userId,
          element: (
            <>
              <span>{user.userPersonalInformation.email}</span>
              <br />
              <span>{name}</span>
            </>
          ),
        };
      }),
    [usersState.users]
  );

  const [userId, setUserId] = useState<string | null>(null);

  const handleUserTypeaheadSelect = useCallback(
    (userId: any) => setUserId(userId),
    []
  );

  const [userSettings, usersSettingsDispatcher] = useReducer(
    userSettingsReducer,
    userSettingsInitialState
  );

  const handleCopyUserSetingsClick = useCallback(() => {
    if (userId === null) {
      return;
    }
    fetchUserSettings({
      dispatch: usersSettingsDispatcher,
      userId,
    });
  }, [userId]);

  const [isSuccessAlertBannerOpen, setIsSuccessAlertBannerOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      userSettings.loading === false &&
      userSettings.errors.length === 0 &&
      userSettings.user
    ) {
      try {
        props.onUserSettingsCallback(userSettings.user);
        setIsSuccessAlertBannerOpen(true);
      } catch (error) {
        setIsSuccessAlertBannerOpen(false);
      }
    }
  }, [userSettings, props.onUserSettingsCallback]);

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleToggleClick = useCallback(
    () => setExpanded(!expanded),
    [expanded]
  );

  const copySettingsSuccessAlertBanner = useMemo(() => {
    return (
      <AlertBanner>
        <Alert
          onCloseClick={() => setIsSuccessAlertBannerOpen(false)}
          showCloseButton={true}
          alertType={AlertType.Success}
          label={
            <SuccessAlertBannerLabel>
              <Icon
                icon={theme.icons.checkCircleOval}
                size={{ height: "22px", width: "22px" }}
              />
              <SuccessAlertBannerTitle>
                {importSucceededStatus}
              </SuccessAlertBannerTitle>
              <SuccessAlertBannerMessage>
                {importSucceededNotification}
              </SuccessAlertBannerMessage>
            </SuccessAlertBannerLabel>
          }
        />
      </AlertBanner>
    );
  }, [setIsSuccessAlertBannerOpen]);

  const copySettingsFailureAlertBanner = useMemo(() => {
    return (
      <AlertBanner>
        <Alert
          onCloseClick={() =>
            usersSettingsDispatcher({ type: FetchUserActionType.Reset })
          }
          showCloseButton={true}
          alertType={AlertType.Danger}
          label={
            <FailureAlertBannerLabel>
              <Icon
                icon={theme.icons.messageIconWarning}
                size={{ height: "20px", width: "20px" }}
              />
              <FailureAlertBannerTitle>
                {importFailedStatus}
              </FailureAlertBannerTitle>
              <FailureAlertBannerMessage>
                {importFailedNotification}
              </FailureAlertBannerMessage>
            </FailureAlertBannerLabel>
          }
        />
      </AlertBanner>
    );
  }, [usersSettingsDispatcher]);

  return (
    <>
      <UserSettingsWrapper>
        <div
          style={{ cursor: "pointer", display: "flex", gap: "14px" }}
          onClick={handleToggleClick}
        >
          <Icon
            icon={expanded ? theme.icons.collapse : theme.icons.expand}
            size={IconSize.Small}
          />{" "}
          {importSettingsFromAnotherUserText}
        </div>
        {expanded && (
          <UserSettingsForm>
            <Typeahead
              value={userId || undefined}
              isLoading={usersState.loading}
              loadingText={typeaheadLoadingText}
              onChange={handleUserTypeaheadChange}
              onSelect={handleUserTypeaheadSelect}
              options={usersSearchTypeaheadOptions}
              wrapperStyle={{ width: "400px" }}
              placeholder={findUserPlaceholder}
              externalFilter={() => true}
            />
            <Button
              disabled={userId === null || userSettings.loading}
              type="button"
              label={buttonLabel}
              size={ButtonSize.Small}
              variant={ButtonVariant.Secondary}
              onClick={handleCopyUserSetingsClick}
            />
          </UserSettingsForm>
        )}
      </UserSettingsWrapper>
      {isSuccessAlertBannerOpen && copySettingsSuccessAlertBanner}
      {userSettings.errors.length > 0 && copySettingsFailureAlertBanner}
    </>
  );
};
