import { useIntl } from "react-intl";

export type translationValue = {
  [key: string]: string;
};

export const useTranslation = (values: translationValue) => {
  const translations: translationValue = {};
  const intl = useIntl();
  Object.keys(values).forEach((k) => {
    translations[k] = intl.formatMessage({ id: values[k] });
  });
  return translations;
};
