export const userFormTranslations = {
  newUserFormTitle: "new-user-page.form-title",
  newUserUnknownErrorMessage: "new-user-page.unknown-error-message",
  editUserFormTitle: "edit-user-page.form-title",
  editUserUnknownErrorMessage: "edit-user-page.unknown-error-message",
  backButtonText: "user-form.back-button",
  createUserButtonText: "user-form.create-user-button",
  editUserButtonText: "user-form.edit-user-button",
  ymparistonettiAndOmaLtLabel: "user-form.environmental-network.tab-label",
  raksanappiLabel: "user-form.raksanappi-tab-label",
  kiinteistonettiLabel: "user-form.kiinteistonetti-tab-label",
  kiinteistonettiUserAccessLevel: "user-form.kiinteistonetti.user-access-level",
  kiinteistonettiEmailInstructionsSelectLabel:
    "user-form.kiinteistonetti.email-instructions-select-label",
  kiinteistonettiEmailInstructionsCheckboxLabel:
    "user-form.kiinteistonetti.email-instructions-checkbox-label",
  firstNameLabel: "user-form.first-name",
  lastNameLabel: "user-form.last-name",
  emailLabel: "user-form.email",
  phoneNumberLabel: "user-form.phone-number",
  wrongValuesForUser: "user-form.wrong-values-for-user",
  ymparistonettiUserAccessLevel: "user-form.ymparistonetti.user-access-level",
  ymparistonettiPremiumSubscriptionCheckboxLabel:
    "user-form.ymparistonetti.premium-subscription-checkbox-label",
  ymparistonettiEmailInstructionsCheckboxLabel:
    "user-form.ymparistonetti.email-instructions-checkbox-label",
  ymparistonettiEmailInstructionsSelectLabel:
    "user-form.omalt.email-instructions-select-label",
  omaltUserAccessLevel: "user-form.omalt.user-access-level",
  omaltBillingAccessCheckboxLabel:
    "user-form.omalt.billing-role-checkbox-label",
  omaltEmailInstructionsCheckboxLabel:
    "user-form.omalt.email-instructions-checkbox-label",
  omaltEmailInstructionsSelectLabel:
    "user-form.ymparistonetti.email-instructions-select-label",
  raksanappiUserAccessLevel: "user-form.raksanappi.user-access-level",
  raksanappiEmailInstructionsCheckboxLabel:
    "user-form.raksanappi.email-instructions-checkbox-label",
  raksanappiEmailInstructionsSelectLabel:
    "user-form.raksanappi.email-instructions-select-label",
  customerSearchLabel: "user-form.customer-search.label",
  customerSearchPlaceholder: "user-form.customer-search.placeholder",
  worksiteSearchLabel: "user-form.worksite-search.label",
  worksiteSearchPlaceholder: "user-form.worksite-search.placeholder",
};
export type UserFormTranslationsType = typeof userFormTranslations;
