import * as Yup from "yup";

interface ValidateEmail {
  (
    messages: {
      userAlreadyExists: string;
      userSearchFailed: string;
    },
    path: string,
    validate: (email: string) => Promise<boolean>
  ): Yup.TestFunction<string | undefined>;
}

const validateEmailFactory: ValidateEmail = (messages, path, emailExists) => {
  return async function (email, context) {
    if (typeof email === "undefined") {
      return true;
    }

    const getUserSearchFailedError = () =>
      this.createError({
        message: messages.userSearchFailed,
        path: context.path || path,
      });

    try {
      const response = await emailExists(email);
      return !response;
    } catch {
      return getUserSearchFailedError();
    }
  };
};

export default validateEmailFactory;
