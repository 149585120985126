import { Field, FieldProps } from "formik";
import {
  CustomerItem,
  CustomerList,
  InputCheckboxLabel,
  PreviewEmailTemplateButton,
  TypeaheadLabel,
} from "./styled";
import { kiinteistonettiConfig } from "../../utils/constants";
import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import {
  Dropdown,
  InputCheckbox,
  InputRadioGroup,
  Typeahead,
  ThemeType,
} from "@kettu/ui-components";
import { useTheme } from "styled-components";
import { UserFormTranslationsType } from "./UserForm.translations";
import { Customer, EmailInstructions } from "../../utils/types";
import { KiinteistonettiRole } from "../../utils/backendTypes";
import { UserInnerFormValues } from "./UserForm";
import {
  fetchCustomers,
  initialState as customersInitialState,
  reducer as customersReducer,
} from "./UserForm.customers";
import { useFormattedMessage } from "../../utils/useFormattedMessage.hook";

type UserFormKiinteistonettiProps = {
  translations: UserFormTranslationsType;
  setFieldValue: (field: string, value: any) => void;
  handlePreviewEmailTemplateClick: (
    serviceId: string,
    emailInstruction: EmailInstructions
  ) => MouseEventHandler<HTMLButtonElement> | undefined;
  setEmailPreviewElement: (a: any) => void;
  values: UserInnerFormValues;
};

export const UserFormKiinteistonetti = ({
  values,
  translations,
  setFieldValue,
  handlePreviewEmailTemplateClick,
}: UserFormKiinteistonettiProps) => {
  const theme = useTheme() as ThemeType;

  const typeaheadLoadingText = useFormattedMessage(
    "user-form.kiinteistonetti.typeahead-loading-text"
  );

  const [customersState, customersDispatcher] = useReducer(
    customersReducer,
    customersInitialState
  );

  const customers = values.kiinteistonetti.customers;

  const filterOptions = useCallback(
    (customer) => !customers.map((c) => c.id).includes(customer.id),
    [customers]
  );

  const typeaheadOptions = useMemo(
    () =>
      customersState.customers
        .filter(filterOptions)
        .map((customer) => ({ label: customer.name, value: customer })),
    [customersState.customers, filterOptions]
  );

  const handleCustomerTypeaheadChange = useCallback(
    (searchQuery: any) =>
      !!searchQuery &&
      searchQuery.length &&
      fetchCustomers({ dispatch: customersDispatcher, searchQuery }),
    []
  );

  const handleCustomerChangeFactory = useCallback(
    (customerId: Customer["id"]) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const customer = customers.find(
          (customer) => customer.id === customerId
        );
        const prevCustomers = values.kiinteistonetti.customers;

        const nextCustomers = event.target.checked
          ? [...prevCustomers, customer]
          : prevCustomers.filter((customer: any) => customer.id !== customerId);

        setFieldValue("kiinteistonetti.customers", nextCustomers);
      },
    [customers, values.kiinteistonetti.customers]
  );

  const handleCustomerSuggestionChange = useCallback(
    (selectedCustomer: Customer | null) => {
      if (!selectedCustomer) {
        return;
      }

      setFieldValue("kiinteistonetti.customers", [
        ...values.kiinteistonetti.customers,
        selectedCustomer,
      ]);
    },
    [values.kiinteistonetti.customers]
  );

  const renderRoleInput = useCallback(
    (props: FieldProps<KiinteistonettiRole, UserInnerFormValues>) => (
      <InputRadioGroup
        {...props.field}
        options={kiinteistonettiConfig.roles}
        onChange={(value) => {
          props.form.setFieldValue(props.field.name, value);
        }}
      />
    ),
    []
  );

  const renderSendEmailInstructionsCheckbox = useCallback(
    (props: FieldProps<boolean, UserInnerFormValues>) => (
      <InputCheckbox
        {...props.field}
        id="kiinteistonetti-send-email-instructions"
        label={
          <InputCheckboxLabel>
            {translations.kiinteistonettiEmailInstructionsCheckboxLabel}
          </InputCheckboxLabel>
        }
      />
    ),
    []
  );

  const renderEmailInstructionsDropdown = useCallback(
    ({
      field: { onChange, value, ...field },
      meta,
      ...props
    }: FieldProps<EmailInstructions, UserInnerFormValues>) => (
      <div style={{ flexGrow: 1 }}>
        <Dropdown
          defaultValue={kiinteistonettiConfig.emailOptions.find(
            (option) => option.value === meta.initialValue
          )}
          id="kiinteistonetti.email-instructions"
          label={translations.kiinteistonettiEmailInstructionsSelectLabel}
          onChange={(value) =>
            props.form.setFieldValue(field.name, value?.value)
          }
          options={kiinteistonettiConfig.emailOptions}
          value={kiinteistonettiConfig.emailOptions.find(
            (option) => option.value === value
          )}
          {...field}
        />
      </div>
    ),
    []
  );

  const customerList = useMemo(() => {
    return (
      <>
        {customers.map((customer) => {
          return (
            <CustomerItem key={customer.id}>
              <InputCheckbox
                id={`kiinteistonetti-customer--${customer.id}`}
                onChange={handleCustomerChangeFactory(customer.id)}
                checked={values.kiinteistonetti.customers
                  .map((c) => c.id)
                  .includes(customer.id)}
                label={
                  <span
                    style={{ paddingLeft: "16px", display: "inline-block" }}
                  >
                    <b>{customer.name}</b>
                    <br />
                    {customer.id}
                  </span>
                }
              />
            </CustomerItem>
          );
        })}
      </>
    );
  }, [
    customers,
    values.kiinteistonetti.role,
    values.kiinteistonetti.customers,
  ]);

  return (
    <>
      <div
        style={{ padding: "38px 0 0 0", minHeight: "200px", display: "flex" }}
      >
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {theme.icons.kiinteistonetti}{" "}
            {translations.kiinteistonettiUserAccessLevel}
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              padding: "24px 10px 24px 39px",
              flexWrap: "wrap",
            }}
          >
            <Field name="kiinteistonetti.role" children={renderRoleInput} />
          </div>
          <div>
            <div style={{ paddingBottom: "17px" }}>
              <Field
                type="checkbox"
                name="kiinteistonetti.sendEmailInstructions"
                children={renderSendEmailInstructionsCheckbox}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "flex-end",
                padding: "0 0 58px 37px",
                width: "450px",
              }}
            >
              <Field
                type="select"
                name="kiinteistonetti.emailInstructions"
                children={renderEmailInstructionsDropdown}
              />
              <PreviewEmailTemplateButton
                onClick={handlePreviewEmailTemplateClick(
                  kiinteistonettiConfig.id,
                  values.kiinteistonetti.emailInstructions
                )}
              >
                {theme.icons.eye}
              </PreviewEmailTemplateButton>
            </div>
          </div>
        </div>
      </div>
      <TypeaheadLabel>{translations.customerSearchLabel}</TypeaheadLabel>
      <Typeahead
        doNotShowSelection
        externalFilter={filterOptions}
        placeholder={translations.customerSearchPlaceholder}
        isLoading={customersState.loading}
        loadingText={typeaheadLoadingText}
        onChange={handleCustomerTypeaheadChange}
        onSelect={handleCustomerSuggestionChange}
        options={typeaheadOptions}
      />
      <CustomerList>{customerList}</CustomerList>
    </>
  );
};
