type Env = "local" | "qa" | "prod";

const environmentsByUrl: Record<Env, string> = {
  local: "http://iamfoxy-localhost.lassila-tikanoja.fi",
  qa: "https://qa-iam.lt.fi",
  prod: "https://iam.lt.fi",
};

interface FeatureMap {
  IAM3_KIINTEISTONETTI: Env[];
  IAM3_USERS_SEARCH_FILTERS: Env[];
}

const enabledEnvironments: FeatureMap = {
  IAM3_KIINTEISTONETTI: ["local", "qa", "prod"],
  IAM3_USERS_SEARCH_FILTERS: ["local", "qa"],
};

const isFeatureEnabledForThisEnv = (feature: keyof FeatureMap) =>
  enabledEnvironments[feature]
    .map((env) => environmentsByUrl[env])
    .find((url) => window.location.origin.includes(url));

export const IAM3_KIINTEISTONETTI_ENABLED = isFeatureEnabledForThisEnv(
  "IAM3_KIINTEISTONETTI"
);

export const IAM3_USERS_SEARCH_FILTERS_ENABLED = isFeatureEnabledForThisEnv(
  "IAM3_USERS_SEARCH_FILTERS"
);
