import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactPortal } from "../ReactPortal";

const StyledModal = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.background.black};
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  height: 100%;
  inset: 0;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: fixed;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 999;
`;

const StyledModalContent = styled.div`
  width: 100%;
`;

const CloseButton = styled.button.attrs({ type: "button" })`
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.background.white};
  cursor: pointer;
  font-family: ${(props) => props.theme.typography.secondaryFont.family};
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  right: 35px;
  top: 15px;
`;

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
};

export const Modal = ({ children, isOpen, handleClose }: ModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => document.body.removeEventListener("keydown", closeOnEscapeKey);
  }, [handleClose]);

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <StyledModal>
        <CloseButton onClick={handleClose}>&times;</CloseButton>
        <StyledModalContent>{children}</StyledModalContent>
      </StyledModal>
    </ReactPortal>
  );
};
