import Logo from "../../../images/logo.png";
import React, { useMemo } from "react";
import { Row } from "new-design/styled";
import { useIntl } from "react-intl";
import { Title, TitleColor } from "@kettu/ui-components";

export type PageTitleProps = {
  onClick?: () => void;
  title: string;
};

export const PageTitle = (props: PageTitleProps) => {
  const intl = useIntl();
  const alt = useMemo(() => intl.formatMessage({ id: "page-logo.alt" }), []);

  return (
    <Row onClick={props.onClick}>
      <img
        style={{ marginRight: "14px", height: "30px" }}
        src={Logo}
        alt={alt}
      />
      <Title color={TitleColor.MainDark} text={props.title} />
    </Row>
  );
};
