import React, { useMemo, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { get } from "utils/api";
import { EmailInstructions } from "new-design/utils/types";

type ImageProps = {
  show: boolean;
};

const Image = styled.img<ImageProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  margin: auto;
  max-width: 700px;
  width: 80%;
`;

type EmailPreviewProps = {
  service: string;
  welcomeMessageType: EmailInstructions;
};

export const EmailPreview = ({
  service,
  welcomeMessageType,
}: EmailPreviewProps) => {
  const intl = useIntl();
  const imageAlt = useMemo(
    () => intl.formatMessage({ id: "email-preview-image.alt" }),
    []
  );
  const imageElement = useRef<HTMLImageElement>(null);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const onImageLoaded = () => setImageLoaded(true);

  React.useEffect(() => {
    const imageElementCurrent = imageElement.current;
    if (imageElementCurrent) {
      imageElementCurrent.addEventListener("load", onImageLoaded);
      return () =>
        imageElementCurrent.removeEventListener("load", onImageLoaded);
    }
  }, [imageElement]);

  useEffect(() => {
    const fetch = async () => {
      const response = await get(
        `/email/template?service=${service}&emailType=${welcomeMessageType}`
      );
      const text = await response.text();
      setImageUrl(text);
    };
    fetch();
  }, [service, welcomeMessageType]);

  return (
    <Image
      ref={imageElement}
      src={imageUrl}
      alt={imageAlt}
      show={imageLoaded}
    />
  );
};
