import React, { useState } from "react";
import { LoginStatus } from "./types";
import AuthContext from "../contexts/AuthContext";
import { userStorageKey } from "../App";

type GetMeResponse = {
  email: string;
  groups: string[];
  name: string;
  sub: string;
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(
    LoginStatus.LOADING
  );

  const [actorId, setActorId] = useState<string | undefined>();

  const initialize = async () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const url = `${backendUrl}/auth/sso/me`;

    try {
      const response: Response = await fetch(url, { credentials: "include" });
      switch (response.status) {
        case 200:
          const body = (await response.json()) as GetMeResponse;
          localStorage.setItem(userStorageKey, JSON.stringify(body));
          setLoginStatus(LoginStatus.SUCCESS);
          setActorId(body.sub);
          break;
        case 401:
          localStorage.removeItem(userStorageKey);
          setLoginStatus(LoginStatus.LOGIN);
          setActorId(undefined);
          console.log(`Invalid response code from ${url}: ${response.status}.`);
          break;
        default:
          console.log(`Invalid response code from ${url}: ${response.status}.`);
      }
    } catch (err) {
      localStorage.removeItem(userStorageKey);
      setLoginStatus(LoginStatus.UNAUTHORIZED);
      setActorId(undefined);
      console.log(err);
    }
  };

  const value = {
    actorId,
    loginStatus,
    initialize,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
