import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  Button,
  ButtonShape,
  ButtonSize,
  ButtonVariant,
  Header,
  Input,
  Tab,
  Table,
  Tabs,
  Title,
  Icon,
  ThemeType,
  Pagination,
  Filters,
  initialFiltersState,
  FiltersState,
  FiltersTranslations,
} from "@kettu/ui-components";
import { Row } from "new-design/styled";
import { PageTitle } from "../components";
import { get } from "../../utils/api";
import { useFormattedMessage } from "../utils/useFormattedMessage.hook";
import { useIntl } from "react-intl";
import _ from "lodash";
import {
  KiinteistonettiConfig,
  OmaLtConfig,
  RaksanappiConfig,
  RaksanappiRole,
  UserConfig,
  YnetConfig,
} from "new-design/utils/backendTypes";
import { useDebounce } from "new-design/utils/useDebounce";
import {
  Navigation,
  TabClone,
  Search,
  SearchNavigation,
  SearchNavigationWithFilters,
  YnetPremiumSign,
  PaginationContainer,
  ClearFiltersButton,
} from "./styled";
import { IAM3_USERS_SEARCH_FILTERS_ENABLED } from "featureFlags";

type GetUsersResponse = {
  length: number;
  limit: number;
  start: number;
  total: number;
  users: UserConfig[];
};

type QuerySearchParams = {
  query: string;
  page: string;
};

type GetUsersTableConfigProps = {
  nameColumnLabelTranslation: string;
  emailColumnLabelTranslation: string;
  servicesColumnLabelTranslation: string;
};

const getUsersTableConfig = ({
  nameColumnLabelTranslation,
  emailColumnLabelTranslation,
  servicesColumnLabelTranslation,
}: GetUsersTableConfigProps) => [
  {
    valueKey: "name",
    label: nameColumnLabelTranslation,
  },
  {
    valueKey: "email",
    label: emailColumnLabelTranslation,
  },
  {
    valueKey: "services",
    label: servicesColumnLabelTranslation,
    width: "180px",
  },
  {
    valueKey: "isYnetPremium",
    width: "36px",
  },
  {
    valueKey: "action",
    width: "106px",
  },
];

const pageSize = 10;

async function fetchUsers(
  query: string,
  page: number,
  pageSize: number
): Promise<GetUsersResponse> {
  const params = new URLSearchParams({
    query,
    pageSize: pageSize.toString(),
    page: page.toString(),
  });

  const response = await get(`/v2/users?${params.toString()}`);
  return response.json();
}

export const UsersPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme() as ThemeType;

  const [users, setUsers] = useState<UserConfig[]>([]);
  const [usersTotal, setUsersTotal] = useState<number>(0);
  const [filtersExpaned, setFiltersExpanded] = useState<boolean>(false);
  const [filtersState, setFiltersState] =
    useState<FiltersState>(initialFiltersState);

  const searchParams = new URLSearchParams(location.search);
  const parsedSearchParams = Object.fromEntries(
    searchParams
  ) as QuerySearchParams;
  const [searchQuery, setSearchQuery] = useState<string>(
    parsedSearchParams.query || ""
  );
  const [currentPage, setCurrentPage] = useState<number>(0);

  const debouncedSearchQuery = useDebounce(searchQuery, 800);

  const pageTitle = useFormattedMessage("users-page.page-title");
  const usersTab = useFormattedMessage("users-page.user-tab");
  const searchPlaceholder = useFormattedMessage(
    "users-page.search-placeholder"
  );
  const inviteButtonText = useFormattedMessage("users-page.invite-button");
  const paginationLabel = useFormattedMessage("users-page.pagination-label");
  const clearFiltersButtonLabel = useFormattedMessage(
    "users-page.filters-pane.clear-filters"
  );

  const handleSearchQueryChange = useCallback(
    (nextQuery: string) => {
      navigate(`/admin/search?query=${nextQuery}`, {
        replace: true,
      });
      setSearchQuery(nextQuery);
      setCurrentPage(0);
    },
    [navigate, searchQuery, currentPage]
  );

  const usersTableConfig = useMemo(
    () =>
      getUsersTableConfig({
        nameColumnLabelTranslation: intl.formatMessage({
          id: "users-page.user-tab.name-header",
        }),
        emailColumnLabelTranslation: intl.formatMessage({
          id: "users-page.user-tab.email-header",
        }),
        servicesColumnLabelTranslation: intl.formatMessage({
          id: "users-page.services",
        }),
      }),
    []
  );

  const getUserAction = useCallback(
    (userId: string) => (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="button"
          variant={ButtonVariant.Secondary}
          shape={ButtonShape.Round}
          size={ButtonSize.Small}
          onClick={() => navigate(`/admin/user/${userId}`)}
        >
          {theme.icons.edit}
        </Button>
      </div>
    ),
    []
  );

  useEffect(() => {
    const fetch = async () => {
      const getUsersResponse = await fetchUsers(
        debouncedSearchQuery,
        currentPage,
        pageSize
      );
      setUsers(getUsersResponse.users);
      setUsersTotal(getUsersResponse.total);
    };

    if (searchQuery.length > 2) {
      fetch();
    }
  }, [debouncedSearchQuery, currentPage]);

  const getUtilizedServiceIcons = useCallback((user: UserConfig) => {
    const doesUserHasAnyCustomersInOmaltConfigured = (
      omaltConfig: OmaLtConfig
    ) => {
      return (
        omaltConfig.fullAccessCustomers.length > 0 ||
        omaltConfig.partialAccessCustomers.length > 0
      );
    };

    const doesUserHasAnyCustomersInYnetConfigured = (
      ynetConfig: YnetConfig
    ) => {
      return (
        ynetConfig.fullAccessCustomers.length > 0 ||
        ynetConfig.partialAccessCustomers.length > 0
      );
    };

    const doesUserHasAccessToRaksanappi = (
      raksanappiConfig: RaksanappiConfig
    ) => {
      return raksanappiConfig.role === RaksanappiRole.Customer;
    };

    const doesUserHasAnyCustomersInKiinteistonettiConfigured = (
      kiinteistonettiConfig: KiinteistonettiConfig
    ) => {
      return kiinteistonettiConfig.customers.length > 0;
    };

    return (
      <div style={{ display: "flex" }}>
        {doesUserHasAnyCustomersInOmaltConfigured(user.omaLTConfig) && (
          <div style={{ display: "flex", paddingRight: "12px" }}>
            <Icon
              icon={theme.icons.omalt}
              size={{ width: "auto", height: "auto" }}
            />
          </div>
        )}
        {doesUserHasAnyCustomersInYnetConfigured(user.ynetConfig) && (
          <div style={{ display: "flex", paddingRight: "12px" }}>
            <Icon
              icon={theme.icons.ymparistonetti}
              size={{ width: "auto", height: "auto" }}
            />
          </div>
        )}
        {doesUserHasAccessToRaksanappi(user.raksanappiConfig) && (
          <div style={{ display: "flex", paddingRight: "12px" }}>
            <Icon
              icon={theme.icons.raksanappi}
              size={{ width: "auto", height: "auto" }}
            />
          </div>
        )}
        {doesUserHasAnyCustomersInKiinteistonettiConfigured(
          user.kiinteistonettiConfig
        ) && (
          <div style={{ display: "flex" }}>
            <Icon
              icon={theme.icons.kiinteistonetti}
              size={{ width: "auto", height: "auto" }}
            />
          </div>
        )}
      </div>
    );
  }, []);

  const usersWithActions = useMemo(
    () =>
      users.map((user) => {
        const { firstName, lastName, email } = user.userPersonalInformation;

        return {
          name: `${firstName} ${lastName}`,
          email,
          services: getUtilizedServiceIcons(user),
          isYnetPremium: user.ynetConfig.isPremium ? (
            <YnetPremiumSign>€</YnetPremiumSign>
          ) : null,
          action: getUserAction(user.userId),
        };
      }),
    [users, getUserAction]
  );

  const createNewUserButton = useMemo(() => {
    return (
      <Button
        type="button"
        label={inviteButtonText}
        icon={
          <Icon
            icon={theme.icons.userPlus}
            size={{ width: "20px", height: "18px" }}
          />
        }
        onClick={() => navigate("/admin/user")}
      />
    );
  }, [inviteButtonText, navigate]);

  const filtersSelected = !_.isEqual(initialFiltersState, filtersState);

  const expandButtonLabel = useMemo(() => {
    const label = intl.formatMessage({
      id: filtersSelected
        ? "users-page.filters-pane.filters-on"
        : "users-page.filters-pane.filters",
    });

    const icon = filtersExpaned ? (
      <Icon
        icon={theme.icons.arrowUp}
        size={{ width: "18px", height: "12px" }}
      />
    ) : (
      <Icon
        icon={theme.icons.arrowDown}
        size={{ width: "18px", height: "12px" }}
      />
    );

    return (
      <span style={{ display: "flex", gap: "11px" }}>
        {label} {icon}
      </span>
    );
  }, [filtersExpaned, filtersSelected, intl]);

  const filtersTranslations: FiltersTranslations = {
    omaltAdminCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.omalt.admin"
    ),
    omaltCustomerCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.omalt.customer"
    ),
    omaltNoAccessCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.omalt.no-access"
    ),
    ynetAdminCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.ynet.admin"
    ),
    ynetCustomerCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.ynet.customer"
    ),
    ynetNoAccessCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.ynet.no-access"
    ),
    ynetPayingUsersCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.ynet.paying-users"
    ),
    ynetNonPayingUsersCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.ynet.non-paying-users"
    ),
    raksanappiCustomerCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.raksanappi.admin"
    ),
    raksanappiNoAccessCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.raksanappi.none"
    ),
    kiinteistonettiCustomerCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.kiinteistonetti.customer"
    ),
    kiinteistonettiNotAUserCheckboxLabel: useFormattedMessage(
      "users-page.filters-pane.kiinteistonetti.not-user"
    ),
    cancelButtonLabel: useFormattedMessage(
      "users-page.filters-pane.cancel-button"
    ),
    applyFiltersButtonLabel: useFormattedMessage(
      "users-page.filters-pane.apply-filters"
    ),
  };

  return (
    <>
      <Header height="66px">
        <Row gap="87px">
          <PageTitle title={pageTitle} />
          <Tabs onChange={() => {}}>
            <Tab label={pageTitle} />
            <Tab label="" />
          </Tabs>
        </Row>
      </Header>
      <div style={{ padding: "55px 145px" }}>
        <div style={{ marginBottom: "30px" }}>
          <Title height="32px" fontSize="32px" text={pageTitle} />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <Navigation>
            <TabClone>{usersTab}</TabClone>
          </Navigation>
          <div style={{ borderBottom: "1px solid #E6E6E6" }} />
          <Search>
            {IAM3_USERS_SEARCH_FILTERS_ENABLED ? (
              <SearchNavigationWithFilters>
                <Input
                  placeholder={searchPlaceholder}
                  onChange={handleSearchQueryChange}
                  value={searchQuery}
                />
                {filtersExpaned ? (
                  <div style={{ backgroundColor: "#F2F2F2" }}>
                    <div style={{ display: "flex" }}>
                      <Button
                        type="button"
                        label={expandButtonLabel}
                        variant={ButtonVariant.Filters}
                        onClick={() => setFiltersExpanded(!filtersExpaned)}
                      />
                    </div>
                    <Filters
                      values={filtersState}
                      translations={filtersTranslations}
                      onCancel={() => setFiltersExpanded(false)}
                      onSubmit={(filtersState) => {
                        setFiltersState(filtersState);
                        setFiltersExpanded(false);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <Button
                        type="button"
                        label={expandButtonLabel}
                        variant={ButtonVariant.Filters}
                        onClick={() => setFiltersExpanded(!filtersExpaned)}
                      />
                      {filtersSelected && (
                        <ClearFiltersButton
                          type="button"
                          onClick={() =>
                            setFiltersState({ ...initialFiltersState })
                          }
                        >
                          {clearFiltersButtonLabel}{" "}
                          <Icon
                            icon={theme.icons.clear}
                            size={{ width: "16px", height: "16px" }}
                          />
                        </ClearFiltersButton>
                      )}
                    </div>

                    {createNewUserButton}
                  </div>
                )}
              </SearchNavigationWithFilters>
            ) : (
              <SearchNavigation>
                <Input
                  placeholder={searchPlaceholder}
                  onChange={handleSearchQueryChange}
                  value={searchQuery}
                />
                {createNewUserButton}
              </SearchNavigation>
            )}
            <Table config={usersTableConfig} data={usersWithActions} />
          </Search>
          {users.length > 0 && (
            <PaginationContainer>
              <Pagination
                initialPageNumber={currentPage}
                itemsTotal={usersTotal}
                itemsPerPage={pageSize}
                fetchItems={(number) => setCurrentPage(number)}
                label={paginationLabel}
              />
            </PaginationContainer>
          )}
        </div>
      </div>
    </>
  );
};
