import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { get, post } from "utils/api";
import { Navigate } from "react-router-dom";
import Footer from "components/Footer";

const ChangePassword = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordAgain, setPasswordAgain] = useState<string>("");
  const [bogusTicket, setBogusTicket] = useState<boolean>(false);
  const [passwordsOk, validatePasswords] = useState<boolean>(false);
  const ticket: string | null = new URLSearchParams(window.location.search).get(
    "ticket"
  );
  useEffect(() => {
    validatePasswords(password.length >= 3 && password === passwordAgain);
  }, [password, passwordAgain]);
  useEffect(() => {
    if (ticket) {
      get(`/passwords/tickets?ticket=${ticket}`).then((resp) => {
        if (resp.status === 200) {
          resp.json().then((body) => setEmail(body.email));
        } else {
          setBogusTicket(true);
          console.log("Failed to fetch ticket details.");
        }
      });
    }
  }, []);
  const onSubmitPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    post("/passwords/change", {
      password: password,
      passwordAgain: passwordAgain,
      ticket: params.get("ticket"),
    }).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((body) => {
          window.location.href = body.redirectUrl;
        });
      } else {
        console.log("Failed to change password.");
      }
    });
  };
  if (!ticket || bogusTicket) {
    return <Navigate to="passwords/reset" />;
  }
  return (
    <div className="page">
      <div className="header" />
      <div className="content">
        <div className="box">
          {email ? (
            <>
              <h1>Aseta tai vaihda salasana</h1>
              <div className="email">{email}</div>
              <form
                className="password-form"
                noValidate={true}
                onSubmit={onSubmitPassword}
              >
                <div className="field">
                  <label htmlFor="password">Salasana</label>
                  <input
                    type="password"
                    id="password"
                    autoFocus={true}
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.currentTarget.value)
                    }
                  />
                </div>
                <div className="field">
                  <label htmlFor="password-again">Salasana uudestaan</label>
                  <input
                    type="password"
                    id="password-again"
                    autoFocus={false}
                    value={passwordAgain}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPasswordAgain(e.currentTarget.value)
                    }
                  />
                </div>
                <button type="submit" disabled={!passwordsOk}>
                  Aseta salasana
                </button>
              </form>
            </>
          ) : (
            <></>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
