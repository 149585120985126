import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Field, FieldProps } from "formik";
import {
  CustomerItem,
  CustomerList,
  CustomerWorksiteExpandButton,
  CustomerWorksiteItem,
  InputCheckboxLabel,
  PreviewEmailTemplateButton,
  TypeaheadLabel,
} from "./styled";
import { raksanappiConfig } from "../../utils/constants";
import {
  Dropdown,
  InputCheckbox,
  InputRadioGroup,
  Typeahead,
  ThemeType,
  IconSize,
  Icon,
} from "@kettu/ui-components";
import { useTheme } from "styled-components";
import { UserFormTranslationsType } from "./UserForm.translations";
import { RaksanappiRole } from "../../utils/backendTypes";
import {
  Customer,
  CustomerBase,
  EmailInstructions,
  InnerFormWorksite,
  Worksite,
} from "../../utils/types";
import { UserInnerFormValues } from "./UserForm";
import _ from "lodash";
import { useFormattedMessage } from "../../utils/useFormattedMessage.hook";
import {
  fetchWorksitesAndCustomers,
  initialState as worksitesInitialState,
  reducer as worksitesReducer,
} from "./UserForm.worksites";
import { fetchWorksitesForCustomer, isWorksite } from "./UserForm.envNetwork";
import {
  createRaksanappiCustomers,
  raksanappiCustomersMapperFn,
  raksanappiSitesMapperFn,
  raksanappiWorksitesMapperFn,
} from "./mapPropsToValues";

type UserFormRaksanappiProps = {
  translations: UserFormTranslationsType;
  setFieldValue: (field: string, value: any) => void;
  handlePreviewEmailTemplateClick: (
    serviceId: string,
    emailInstruction: EmailInstructions
  ) => MouseEventHandler<HTMLButtonElement> | undefined;
  values: UserInnerFormValues;
};

export const UserFormRaksanappi = ({
  translations,
  handlePreviewEmailTemplateClick,
  values,
  setFieldValue,
}: UserFormRaksanappiProps) => {
  const theme = useTheme() as ThemeType;

  const [customerWorksitesExpanded, setCustomerWorksitesExpanded] = useState<
    Customer["id"][]
  >([]);

  const typeaheadLoadingText = useFormattedMessage(
    "user-form.raksanappi.typeahead-loading-text"
  );

  const raksanappiCustomers = useMemo(() => {
    const customers = Object.values(values.raksanappi.customers).map(
      (customer) => {
        const sites = Object.values(customer.sites).map((site) => {
          const worksites = _.values(site.worksites).map((worksite) => {
            return {
              worksite: worksite.worksite,
              config: {
                isRaksanappiSelected:
                  worksite.config.isRaksanappiSelected || false,
              },
            };
          });
          return {
            site: site.site,
            config: {
              isRaksanappiSelected: Object.values(worksites).every(
                (worksite) => worksite.config.isRaksanappiSelected
              ),
            },
            worksites: _.sortBy(worksites, ["worksite.name", "worksite.id"]),
          };
        });

        return {
          customer: customer.customer,
          config: {
            isRaksanappiSelected: Object.values(sites).every(
              (site) => site.config.isRaksanappiSelected
            ),
          },
          sites: _.sortBy(sites, ["site.name", "site.id"]),
        };
      }
    );

    return _.sortBy(customers, ["customer.name", "customer.id"]);
  }, [values.raksanappi.customers]);

  const [worksitesAndCustomersState, worksitesAndCustomersDispatcher] =
    useReducer(worksitesReducer, worksitesInitialState);

  const filterOptions = useCallback((worksite) => true, [raksanappiCustomers]);

  const typeaheadOptions = useMemo(() => {
    const worksitesByCustomers = _.chain(worksitesAndCustomersState.worksites)
      .groupBy((worksite) => worksite.site.customer.id)
      .flatMap((worksites) => {
        const worksite = worksites[0];
        const customer = worksite.site.customer;

        const customerOption = {
          index: customer.name,
          label: customer.name,
          value: customer,
        };
        const worksiteOptions = worksites
          .filter((worksite) => worksite.activity === "active")
          .map((worksite) => ({
            index: `${customer.name} ${worksite.name}`,
            label: `    ${worksite.name}`,
            value: worksite,
          }));

        return [customerOption, ...worksiteOptions];
      })
      .value();

    return _.sortBy(
      [
        ...worksitesAndCustomersState.customers.map((customer) => ({
          index: customer.name,
          label: customer.name,
          value: customer,
        })),
        ...worksitesByCustomers,
      ],
      ["index"]
    );
  }, [values.raksanappi.customers, worksitesAndCustomersState, filterOptions]);

  const handleWorksitesTypeaheadChange = useCallback(
    (searchQuery: any) =>
      !!searchQuery &&
      searchQuery.length &&
      fetchWorksitesAndCustomers({
        includeInactive: false,
        dispatch: worksitesAndCustomersDispatcher,
        searchQuery,
      }),
    []
  );

  const handleWorksiteCustomerChangeFactory = useCallback(
    (customerId: Customer["id"]) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(
          "raksanappi.customers",
          raksanappiCustomersMapperFn(
            values.raksanappi.customers,
            (customer) => customer.customer.id === customerId,
            (sites) => {
              return raksanappiSitesMapperFn(
                sites,
                () => true,
                (worksites) => {
                  return raksanappiWorksitesMapperFn(
                    worksites,
                    () => true,
                    (worksite) => {
                      return {
                        worksite: worksite.worksite,
                        config: {
                          isRaksanappiSelected: event.target.checked,
                        },
                      };
                    }
                  );
                }
              );
            }
          )
        );
      },
    [values.raksanappi.customers]
  );

  const handleWorksiteChangeFactory = useCallback(
    (worksite: InnerFormWorksite) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(
          "raksanappi.customers",
          raksanappiCustomersMapperFn(
            values.raksanappi.customers,
            (customer) => customer.customer.id === worksite.customerId,
            (sites) => {
              return raksanappiSitesMapperFn(
                sites,
                (site) => site.site.id === worksite.siteId,
                (worksites) => {
                  return raksanappiWorksitesMapperFn(
                    worksites,
                    (w) => w.worksite.id === worksite.id,
                    (worksite) => {
                      return {
                        worksite: worksite.worksite,
                        config: {
                          isRaksanappiSelected: event.target.checked,
                        },
                      };
                    }
                  );
                }
              );
            }
          )
        );
      },
    [values.raksanappi.customers]
  );

  const handleCustomerWorksiteExpandButtonClick = useCallback(
    (customerId: Customer["id"]) => {
      if (customerWorksitesExpanded.includes(customerId)) {
        return setCustomerWorksitesExpanded(
          customerWorksitesExpanded.filter((id) => id !== customerId)
        );
      }
      setCustomerWorksitesExpanded([...customerWorksitesExpanded, customerId]);
    },
    [customerWorksitesExpanded, setCustomerWorksitesExpanded]
  );

  const handleTypeAheadSelect = useCallback(
    async (value: Worksite | CustomerBase | null) => {
      if (!value) {
        return;
      }

      const customer = isWorksite(value) ? value.site.customer : value;

      try {
        const { worksites } = await fetchWorksitesForCustomer(customer);

        const activeWorksites = worksites.filter(
          (worksite) => worksite.activity === "active"
        );

        const selectedCustomerWorksites = raksanappiCustomers
          .find((c) => c.customer.id === customer?.id)
          ?.sites?.reduce((pv, cv) => {
            cv.worksites.forEach(
              (w) => w.config.isRaksanappiSelected && pv.push(w.worksite.id)
            );
            return pv;
          }, [] as string[]) || [];

        const raksanappiSelectedWorksites = isWorksite(value)
          ? [...selectedCustomerWorksites, value.id]
          : activeWorksites.map((worksite) => worksite.id);

        setFieldValue("raksanappi.customers", {
          ...values.raksanappi.customers,
          ...createRaksanappiCustomers(
            activeWorksites,
            raksanappiSelectedWorksites
          ),
        });

        if (
          isWorksite(value) &&
          !customerWorksitesExpanded.includes(customer.id)
        ) {
          handleCustomerWorksiteExpandButtonClick(customer.id);
        }
      } catch {}
    },
    [
      values.raksanappi.customers,
      customerWorksitesExpanded,
      handleCustomerWorksiteExpandButtonClick,
      raksanappiCustomers,
    ]
  );

  const renderRaksanappiRoleInput = useCallback(
    (props: FieldProps<RaksanappiRole, UserInnerFormValues>) => (
      <InputRadioGroup
        {...props.field}
        options={raksanappiConfig.roles}
        onChange={(value) => props.form.setFieldValue(props.field.name, value)}
      />
    ),
    []
  );

  const renderRaksanappiSendEmailInstructionsCheckbox = useCallback(
    (props: FieldProps<boolean, UserInnerFormValues>) => (
      <InputCheckbox
        {...props.field}
        id="raksanappi-send-email-instructions"
        label={
          <InputCheckboxLabel>
            {translations.raksanappiEmailInstructionsCheckboxLabel}
          </InputCheckboxLabel>
        }
      />
    ),
    []
  );

  const renderRaksanappiEmailInstructionsDropdown = useCallback(
    ({
      field: { onChange, value, ...field },
      meta,
      ...props
    }: FieldProps<EmailInstructions, UserInnerFormValues>) => (
      <div style={{ flexGrow: 1 }}>
        <Dropdown
          defaultValue={raksanappiConfig.emailOptions.find(
            (option) => option.value === meta.initialValue
          )}
          id="raksanappi.email-instructions"
          label={translations.raksanappiEmailInstructionsSelectLabel}
          onChange={(value) => props.form.setFieldValue(field.name, value)}
          options={raksanappiConfig.emailOptions}
          value={raksanappiConfig.emailOptions.find(
            (option) => option.value === value
          )}
          {...field}
        />
      </div>
    ),
    []
  );

  const raksanappiCustomerList = useMemo(
    () =>
      raksanappiCustomers.map((customer) => {
        const customerId = customer.customer.id;
        const worksites = customer.sites.flatMap((site) => site.worksites);
        const showCustomersWorksites = customerWorksitesExpanded.includes(
          customer.customer.id
        );
        return (
          <div key={customerId}>
            <CustomerItem>
              <div style={{ display: "flex", alignItems: "start" }}>
                <InputCheckbox
                  id={`raksanappi-customer--${customerId}`}
                  onChange={handleWorksiteCustomerChangeFactory(customerId)}
                  checked={customer.config.isRaksanappiSelected}
                  indeterminate={worksites.some(
                    (worksite) => worksite.config.isRaksanappiSelected
                  )}
                  label={
                    <span
                      style={{ paddingLeft: "16px", display: "inline-block" }}
                    >
                      <b>{customer.customer.name}</b>
                      <br />
                      {customer.customer.id}
                    </span>
                  }
                />
                <CustomerWorksiteExpandButton
                  onClick={() =>
                    handleCustomerWorksiteExpandButtonClick(
                      customer.customer.id
                    )
                  }
                >
                  {customerWorksitesExpanded.includes(customer.customer.id) ? (
                    <Icon icon={theme.icons.arrowDown} size={IconSize.Small} />
                  ) : (
                    <Icon icon={theme.icons.arrowRight} size={IconSize.Small} />
                  )}
                </CustomerWorksiteExpandButton>
              </div>
            </CustomerItem>
            {showCustomersWorksites &&
              worksites.map((worksite) => {
                const worksiteId = worksite.worksite.id;
                return (
                  <CustomerWorksiteItem key={worksiteId}>
                    <InputCheckbox
                      id={`worksite--${worksiteId}`}
                      onChange={handleWorksiteChangeFactory(worksite.worksite)}
                      checked={worksite.config.isRaksanappiSelected}
                      label={
                        <span
                          style={{
                            paddingLeft: "16px",
                            display: "inline-block",
                          }}
                        >
                          <b>{worksite.worksite.name2}</b>
                          <br />
                          {worksiteId}
                        </span>
                      }
                    />
                  </CustomerWorksiteItem>
                );
              })}
          </div>
        );
      }),
    [raksanappiCustomers, customerWorksitesExpanded]
  );

  return (
    <>
      <div
        style={{ padding: "38px 0 0 0", minHeight: "200px", display: "flex" }}
      >
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {theme.icons.raksanappi} {translations.raksanappiUserAccessLevel}
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              padding: "24px 10px 24px 39px",
              flexWrap: "wrap",
            }}
          >
            <Field
              name="raksanappi.role"
              children={renderRaksanappiRoleInput}
            />
          </div>
          <div style={{ padding: "46px 0 58px 0" }}>
            <div style={{ paddingBottom: "17px" }}>
              <Field
                type="checkbox"
                name="raksanappi.sendEmailInstructions"
                children={renderRaksanappiSendEmailInstructionsCheckbox}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "flex-end",
                padding: "0 0 58px 37px",
                width: "450px",
              }}
            >
              <Field
                type="select"
                name="raksanappi.emailInstructions"
                children={renderRaksanappiEmailInstructionsDropdown}
              />
              <PreviewEmailTemplateButton
                onClick={handlePreviewEmailTemplateClick(
                  raksanappiConfig.id,
                  values.raksanappi.emailInstructions
                )}
              >
                {theme.icons.eye}
              </PreviewEmailTemplateButton>
            </div>
          </div>
        </div>
      </div>
      <TypeaheadLabel>{translations.worksiteSearchLabel}</TypeaheadLabel>
      <Typeahead
        doNotShowSelection
        externalFilter={filterOptions}
        isLoading={worksitesAndCustomersState.loading}
        loadingText={typeaheadLoadingText}
        onChange={handleWorksitesTypeaheadChange}
        onSelect={handleTypeAheadSelect}
        options={typeaheadOptions}
      />
      <CustomerList>{raksanappiCustomerList}</CustomerList>
    </>
  );
};
