import _ from "lodash";
import { Customer } from "new-design/utils/types";
import { get } from "utils/api";

enum FetchCustomersActionType {
  Request = "request",
  Success = "success",
  Failure = "failure",
  Reset = "reset",
}

type FetchCustomersAction = {
  type: FetchCustomersActionType;
  results?: Customer[];
  error?: string;
};

export type FetchCustomersState = {
  loading: boolean;
  error: null | string;
  customers: Customer[];
};

export const initialState: FetchCustomersState = {
  loading: false,
  error: null,
  customers: [],
};

export const reducer = (
  state: FetchCustomersState,
  action: FetchCustomersAction
): FetchCustomersState => {
  const newState = {
    [FetchCustomersActionType.Request]: {
      loading: true,
    },
    [FetchCustomersActionType.Success]: {
      loading: false,
      customers: action.results,
    },
    [FetchCustomersActionType.Failure]: {
      loading: false,
      error: action.error,
    },
    [FetchCustomersActionType.Reset]: initialState,
  };

  return Object.assign({}, state, newState[action.type]);
};

type fetchCustomersProps = {
  searchQuery: string;
  dispatch: React.Dispatch<FetchCustomersAction>;
  omitCustomers?: Customer["id"][];
};

export const fetchCustomers = _.debounce(
  async ({
    searchQuery,
    dispatch,
    omitCustomers = [],
  }: fetchCustomersProps) => {
    dispatch({ type: FetchCustomersActionType.Request });
    try {
      const response = await get(`/customers?query=${searchQuery}`);
      const body = (await response.json()) as { customers: Customer[] };

      dispatch({
        type: FetchCustomersActionType.Success,
        results: body.customers.filter(
          (customer) => !omitCustomers.includes(customer.id)
        ),
      });
    } catch (error) {
      dispatch({
        type: FetchCustomersActionType.Failure,
        error: "",
      });
    }
  },
  500
);
