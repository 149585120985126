import { Field, FieldMetaProps, FieldProps } from "formik";
import React, { useCallback } from "react";
import { InputField } from "@kettu/ui-components";
import { UserInnerFormValues } from "./UserForm";
import { UserFormTranslationsType } from "./UserForm.translations";
import { CustomInfoMessage } from "./styled";
import * as Yup from "yup";
import { RaksanappiRole } from "../../utils/backendTypes";
import { useVerifyEmail } from "new-design/utils/useVerifyEmail";

type UserFormPersonalInfoProps = {
  translations: UserFormTranslationsType;
  values: any;
  validationSchema: Yup.ObjectSchema<any>;
  getInputFieldAdditionalProps: (
    name: string,
    b: FieldMetaProps<any>,
    messageComponent?: any
  ) => { message: JSX.Element; valid: boolean; invalid: boolean };
};

const isRaksanappiCustomer = (role: RaksanappiRole) => {
  return role === RaksanappiRole.Customer;
};

export const isEmailFormatInvalid = (message: string | undefined) =>
  message === "Virheellinen sähköposti";

const isRequired = (validationSchema: any, name: string) => {
  const fieldValidationSchema = Yup.reach(validationSchema, name);
  const description = fieldValidationSchema.describe();
  const tests = description.tests;
  return !!tests.find((test: any) => test.name === "required");
};

export const UserFormPersonalInfo = ({
  translations,
  validationSchema,
  getInputFieldAdditionalProps,
  values,
}: UserFormPersonalInfoProps) => {
  const { isLoading } = useVerifyEmail();

  const renderFirstNameInput = useCallback(
    ({ field, meta }: FieldProps<string, UserInnerFormValues>) => (
      <InputField
        label={translations.firstNameLabel}
        required={isRequired(validationSchema, field.name)}
        {...getInputFieldAdditionalProps(field.name, meta)}
        {...field}
      />
    ),
    []
  );

  const renderLastNameInput = useCallback(
    ({ field, meta }: FieldProps<string, UserInnerFormValues>) => (
      <InputField
        label={translations.lastNameLabel}
        required={isRequired(validationSchema, field.name)}
        {...getInputFieldAdditionalProps(field.name, meta)}
        {...field}
      />
    ),
    []
  );

  const renderEmailInput = useCallback(
    ({ field, meta }: FieldProps<string, UserInnerFormValues>) => (
      <>
        <InputField
          label={translations.emailLabel}
          required={isRequired(validationSchema, field.name)}
          {...getInputFieldAdditionalProps(field.name, meta)}
          {...field}
        />
        {isLoading && (
          <CustomInfoMessage>Tarkistetaan sähköpostia...</CustomInfoMessage>
        )}
      </>
    ),
    [isLoading]
  );
  const renderPhoneNumberInput = useCallback(
    ({ field, meta }: FieldProps<string, UserInnerFormValues>) => (
      <InputField
        label={translations.phoneNumberLabel}
        placeholder="esim. 0400002000"
        required={isRaksanappiCustomer(values.raksanappi.role)}
        {...getInputFieldAdditionalProps(field.name, meta)}
        {...field}
      />
    ),
    []
  );

  return (
    <div style={{ padding: "32px 0 46px 0" }}>
      <div style={{ display: "flex", gap: "16px", paddingBottom: "34px" }}>
        <div style={{ width: "370px" }}>
          <Field
            name="personalInfo.firstName"
            children={renderFirstNameInput}
          />
        </div>
        <div style={{ width: "370px" }}>
          <Field name="personalInfo.lastName" children={renderLastNameInput} />
        </div>
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <div style={{ width: "450px" }}>
          <Field name="personalInfo.email" children={renderEmailInput} />
        </div>
        <div style={{ width: "290px" }}>
          <Field
            name="personalInfo.phoneNumber"
            children={renderPhoneNumberInput}
          />
        </div>
      </div>
    </div>
  );
};
