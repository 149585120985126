import {
  ynetConfig,
  omaltConfig,
  raksanappiConfig,
  kiinteistonettiConfig,
} from "new-design/utils/constants";
import {
  Worksite,
  EmailInstructions,
  InnerFormWorksite,
  User,
  Site,
  CustomerBase,
} from "new-design/utils/types";
import {
  RaksanappiRole,
  YnetRole,
  OmaltRole,
  YnetConfig,
  OmaLtConfig,
  RaksanappiResponseData,
} from "new-design/utils/backendTypes";
import {
  UserFormProps,
  UserInnerFormProps,
  UserInnerFormValues,
} from "./UserForm";
import _ from "lodash";

export const squashWorksite = (worksite: Worksite): InnerFormWorksite => {
  return {
    ..._.omit(worksite, ["site"]),
    customerId: worksite.site.customer.id,
    customerName: worksite.site.customer.name,
    siteId: worksite.site.id,
    siteName: worksite.site.name,
  };
};

export type InnerFormYnetWorksite = {
  worksite: InnerFormWorksite;
  config: {
    isYnetSelected: boolean;
  };
};

export type InnerFormYnetWorksites = Record<
  Worksite["id"],
  InnerFormYnetWorksite
>;

export type InnerFormYnetSite = {
  site: {
    id: Site["id"];
    name: Site["name"];
  };
  config: {
    isYnetSelected: boolean;
  };
  worksites: Record<string, InnerFormYnetWorksite>;
};

export type InnerFormYnetSites = Record<Site["id"], InnerFormYnetSite>;

export type InnerFormYnetCustomer = {
  customer: CustomerBase;
  config: {
    isYnetSelected: boolean;
  };
  sites: Record<CustomerBase["id"], InnerFormYnetSite>;
};

export type InnerFormYnetCustomers = Record<
  CustomerBase["id"],
  InnerFormYnetCustomer
>;

export const ynetCustomersMapperFn = (
  customers: InnerFormYnetCustomers,
  filterPredicateFn: (customer: InnerFormYnetCustomer) => boolean,
  sitesMapperFn: (sites: InnerFormYnetSites) => InnerFormYnetSites
): InnerFormYnetCustomers => {
  const groups = _.partition(Object.entries(customers), ([, customer]) =>
    filterPredicateFn(customer)
  );

  return Object.fromEntries([
    ...groups[0].map(([, customer]) => {
      const sites = sitesMapperFn(customer.sites);

      return [
        customer.customer.id,
        {
          customer: customer.customer,
          config: {
            isYnetSelected: Object.values(sites).every(
              (site) => site.config.isYnetSelected
            ),
          },
          sites,
        },
      ];
    }),
    ...groups[1],
  ]);
};

export const ynetSitesMapperFn = (
  sites: InnerFormYnetSites,
  filterPredicateFn: (site: InnerFormYnetSite) => boolean,
  worksitesMapperFn: (
    worksites: InnerFormYnetWorksites
  ) => InnerFormYnetWorksites
): InnerFormYnetSites => {
  const groups = _.partition(Object.entries(sites), ([, site]) =>
    filterPredicateFn(site)
  );

  return Object.fromEntries([
    ...groups[0].map(([, site]) => {
      const worksites = worksitesMapperFn(site.worksites);
      return [
        site.site.id,
        {
          site: site.site,
          config: {
            isYnetSelected: Object.values(worksites).every(
              (worksite) => worksite.config.isYnetSelected
            ),
          },
          worksites,
        },
      ];
    }),
    ...groups[1],
  ]);
};

export const ynetWorksitesMapperFn = (
  worksites: InnerFormYnetWorksites,
  filterPredicateFn: (worksite: InnerFormYnetWorksite) => boolean,
  builderFn: (worksite: InnerFormYnetWorksite) => InnerFormYnetWorksite
): InnerFormYnetWorksites => {
  const groups = _.partition(Object.entries(worksites), ([, worksite]) =>
    filterPredicateFn(worksite)
  );

  return Object.fromEntries([
    ...groups[0].map(([worksiteId, worksite]) => {
      return [worksiteId, builderFn(worksite)];
    }),
    ...groups[1],
  ]);
};

export const getYnetSelectedWorksites = (
  customers: InnerFormYnetCustomers
): Worksite["id"][] => {
  return Object.values(customers).flatMap((customer) => {
    return Object.values(customer.sites).flatMap((site) => {
      return Object.values(site.worksites)
        .filter((worksite) => worksite.config.isYnetSelected)
        .map((worksite) => worksite.worksite.id);
    });
  });
};

export const createYnetCustomers = (
  worksites: Worksite[],
  selectedWorksiteIds: Worksite["id"][]
): InnerFormYnetCustomers => {
  const worksitesGrupedByCustomerId = _.groupBy(
    (worksites || []).map(squashWorksite),
    (worksite) => worksite.customerId
  );

  return Object.fromEntries(
    Object.entries(worksitesGrupedByCustomerId).map(
      ([customerId, worksites]) => {
        const customer = {
          id: worksites[0].customerId,
          name: worksites[0].customerName,
        };

        const sitesGrupedBySiteId = _.groupBy(
          worksites || [],
          (worksite) => worksite.siteId
        );

        const sites: InnerFormYnetSites = Object.fromEntries(
          Object.entries(sitesGrupedBySiteId).map(([siteId, siteWorksites]) => {
            const site = {
              id: siteWorksites[0].siteId,
              name: siteWorksites[0].siteName,
            };

            const worksites: InnerFormYnetWorksites = Object.fromEntries(
              siteWorksites.map((siteWorksite) => {
                return [
                  siteWorksite.id,
                  {
                    worksite: siteWorksite,
                    config: {
                      isYnetSelected: selectedWorksiteIds.includes(
                        siteWorksite.id
                      ),
                    },
                  },
                ];
              })
            );

            return [
              siteId,
              {
                site,
                config: {
                  isYnetSelected: Object.values(worksites).every(
                    (worksite) => worksite.config.isYnetSelected
                  ),
                },
                worksites,
              },
            ];
          })
        );

        return [
          customerId,
          {
            customer,
            config: {
              isYnetSelected: Object.values(sites).every(
                (site) => site.config.isYnetSelected
              ),
            },
            sites,
          },
        ];
      }
    )
  );
};

export type InnerFormOmaltWorksite = {
  worksite: InnerFormWorksite;
  config: {
    isOmaltSelected: boolean;
  };
};

export type InnerFormOmaltWorksites = Record<
  Worksite["id"],
  InnerFormOmaltWorksite
>;

export type InnerFormOmaltSite = {
  site: {
    id: Site["id"];
    name: Site["name"];
  };
  config: {
    isOmaltSelected: boolean;
  };
  worksites: Record<string, InnerFormOmaltWorksite>;
};

export type InnerFormOmaltSites = Record<Site["id"], InnerFormOmaltSite>;

export type InnerFormOmaltCustomer = {
  customer: CustomerBase;
  config: {
    isOmaltSelected: boolean;
  };
  sites: Record<CustomerBase["id"], InnerFormOmaltSite>;
};

export type InnerFormOmaltCustomers = Record<
  CustomerBase["id"],
  InnerFormOmaltCustomer
>;

export const omaltCustomersMapperFn = (
  customers: InnerFormOmaltCustomers,
  filterPredicateFn: (customer: InnerFormOmaltCustomer) => boolean,
  sitesMapperFn: (sites: InnerFormOmaltSites) => InnerFormOmaltSites
): InnerFormOmaltCustomers => {
  const groups = _.partition(Object.entries(customers), ([, customer]) =>
    filterPredicateFn(customer)
  );

  return Object.fromEntries([
    ...groups[0].map(([, customer]) => {
      const sites = sitesMapperFn(customer.sites);

      return [
        customer.customer.id,
        {
          customer: customer.customer,
          config: {
            isOmaltSelected: Object.values(sites).every(
              (site) => site.config.isOmaltSelected
            ),
          },
          sites,
        },
      ];
    }),
    ...groups[1],
  ]);
};

export const omaltSitesMapperFn = (
  sites: InnerFormOmaltSites,
  filterPredicateFn: (site: InnerFormOmaltSite) => boolean,
  worksitesMapperFn: (
    worksites: InnerFormOmaltWorksites
  ) => InnerFormOmaltWorksites
): InnerFormOmaltSites => {
  const groups = _.partition(Object.entries(sites), ([, site]) =>
    filterPredicateFn(site)
  );

  return Object.fromEntries([
    ...groups[0].map(([, site]) => {
      const worksites = worksitesMapperFn(site.worksites);
      return [
        site.site.id,
        {
          site: site.site,
          config: {
            isOmaltSelected: Object.values(worksites).every(
              (worksite) => worksite.config.isOmaltSelected
            ),
          },
          worksites,
        },
      ];
    }),
    ...groups[1],
  ]);
};

export const omaltWorksitesMapperFn = (
  worksites: InnerFormOmaltWorksites,
  filterPredicateFn: (worksite: InnerFormOmaltWorksite) => boolean,
  builderFn: (worksite: InnerFormOmaltWorksite) => InnerFormOmaltWorksite
): InnerFormOmaltWorksites => {
  const groups = _.partition(Object.entries(worksites), ([, worksite]) =>
    filterPredicateFn(worksite)
  );

  return Object.fromEntries([
    ...groups[0].map(([worksiteId, worksite]) => [
      worksiteId,
      builderFn(worksite),
    ]),
    ...groups[1],
  ]);
};

export const getOmaltSelectedWorksites = (
  customers: InnerFormOmaltCustomers
): Worksite["id"][] => {
  return Object.values(customers).flatMap((customer) => {
    return Object.values(customer.sites).flatMap((site) => {
      return Object.values(site.worksites)
        .filter((worksite) => worksite.config.isOmaltSelected)
        .map((worksite) => worksite.worksite.id);
    });
  });
};

export const createOmaltCustomers = (
  worksites: Worksite[],
  selectedWorksiteIds: Worksite["id"][]
): InnerFormOmaltCustomers => {
  const worksitesGrupedByCustomerId = _.groupBy(
    (worksites || []).map(squashWorksite),
    (worksite) => worksite.customerId
  );

  return Object.fromEntries(
    Object.entries(worksitesGrupedByCustomerId).map(
      ([customerId, worksites]) => {
        const customer: CustomerBase = {
          id: worksites[0].customerId,
          name: worksites[0].customerName,
        };

        const sitesGrupedBySiteId = _.groupBy(
          worksites || [],
          (worksite) => worksite.siteId
        );

        const sites: InnerFormOmaltSites = Object.fromEntries(
          Object.entries(sitesGrupedBySiteId).map(([siteId, siteWorksites]) => {
            const site = {
              id: siteWorksites[0].siteId,
              name: siteWorksites[0].siteName,
            };

            const worksites: InnerFormOmaltWorksites = Object.fromEntries(
              siteWorksites.map((siteWorksite) => {
                return [
                  siteWorksite.id,
                  {
                    worksite: siteWorksite,
                    config: {
                      isOmaltSelected: selectedWorksiteIds.includes(
                        siteWorksite.id
                      ),
                    },
                  },
                ];
              })
            );

            return [
              siteId,
              {
                site,
                config: {
                  isOmaltSelected: Object.values(worksites).every(
                    (worksite) => worksite.config.isOmaltSelected
                  ),
                },
                worksites,
              },
            ];
          })
        );

        return [
          customerId,
          {
            customer,
            config: {
              isOmaltSelected: _.values(sites).every(
                (site) => site.config.isOmaltSelected
              ),
            },
            sites,
          },
        ];
      }
    )
  );
};

export type InnerFormRaksanappiWorksite = {
  worksite: InnerFormWorksite;
  config: {
    isRaksanappiSelected: boolean;
  };
};

export type InnerFormRaksanappiWorksites = Record<
  Worksite["id"],
  InnerFormRaksanappiWorksite
>;

export type InnerFormRaksanappiSite = {
  site: {
    id: Site["id"];
    name: Site["name"];
  };
  config: {
    isRaksanappiSelected: boolean;
  };
  worksites: Record<string, InnerFormRaksanappiWorksite>;
};

export type InnerFormRaksanappiSites = Record<
  Site["id"],
  InnerFormRaksanappiSite
>;

export type InnerFormRaksanappiCustomer = {
  customer: CustomerBase;
  config: {
    isRaksanappiSelected: boolean;
  };
  sites: Record<CustomerBase["id"], InnerFormRaksanappiSite>;
};

export type InnerFormRaksanappiCustomers = Record<
  CustomerBase["id"],
  InnerFormRaksanappiCustomer
>;

export const raksanappiCustomersMapperFn = (
  customers: InnerFormRaksanappiCustomers,
  filterPredicateFn: (customer: InnerFormRaksanappiCustomer) => boolean,
  sitesMapperFn: (sites: InnerFormRaksanappiSites) => InnerFormRaksanappiSites
): InnerFormRaksanappiCustomers => {
  const groups = _.partition(Object.entries(customers), ([, customer]) =>
    filterPredicateFn(customer)
  );

  return Object.fromEntries([
    ...groups[0].map(([, customer]) => {
      const sites = sitesMapperFn(customer.sites);

      return [
        customer.customer.id,
        {
          customer: customer.customer,
          config: {
            isRaksanappiSelected: Object.values(sites).every(
              (site) => site.config.isRaksanappiSelected
            ),
          },
          sites,
        },
      ];
    }),
    ...groups[1],
  ]);
};

export const raksanappiSitesMapperFn = (
  sites: InnerFormRaksanappiSites,
  filterPredicateFn: (site: InnerFormRaksanappiSite) => boolean,
  worksitesMapperFn: (
    worksites: InnerFormRaksanappiWorksites
  ) => InnerFormRaksanappiWorksites
): InnerFormRaksanappiSites => {
  const groups = _.partition(Object.entries(sites), ([, site]) =>
    filterPredicateFn(site)
  );

  return Object.fromEntries([
    ...groups[0].map(([, site]) => {
      const worksites = worksitesMapperFn(site.worksites);
      return [
        site.site.id,
        {
          site: site.site,
          config: {
            isRaksanappiSelected: Object.values(worksites).every(
              (worksite) => worksite.config.isRaksanappiSelected
            ),
          },
          worksites,
        },
      ];
    }),
    ...groups[1],
  ]);
};

export const raksanappiWorksitesMapperFn = (
  worksites: InnerFormRaksanappiWorksites,
  filterPredicateFn: (worksite: InnerFormRaksanappiWorksite) => boolean,
  builderFn: (
    worksite: InnerFormRaksanappiWorksite
  ) => InnerFormRaksanappiWorksite
): InnerFormRaksanappiWorksites => {
  const groups = _.partition(Object.entries(worksites), ([, worksite]) =>
    filterPredicateFn(worksite)
  );

  return Object.fromEntries([
    ...groups[0].map(([worksiteId, worksite]) => [
      worksiteId,
      builderFn(worksite),
    ]),
    ...groups[1],
  ]);
};

export const createRaksanappiCustomers = (
  worksites: Worksite[],
  selectedWorksiteIds: Worksite["id"][]
): InnerFormRaksanappiCustomers => {
  const worksitesGrupedByCustomerId = _.groupBy(
    (worksites || []).map(squashWorksite),
    (worksite) => worksite.customerId
  );

  return Object.fromEntries(
    Object.entries(worksitesGrupedByCustomerId).map(
      ([customerId, worksites]) => {
        const customer: CustomerBase = {
          id: worksites[0].customerId,
          name: worksites[0].customerName,
        };

        const sitesGrupedBySiteId = _.groupBy(
          worksites || [],
          (worksite) => worksite.siteId
        );

        const sites: Record<Site["id"], InnerFormRaksanappiSite> =
          Object.fromEntries(
            Object.entries(sitesGrupedBySiteId).map(
              ([siteId, siteWorksites]) => {
                const site = {
                  id: siteWorksites[0].siteId,
                  name: siteWorksites[0].siteName,
                };

                const worksites: Record<
                  Worksite["id"],
                  InnerFormRaksanappiWorksite
                > = Object.fromEntries(
                  siteWorksites.map((siteWorksite) => {
                    return [
                      siteWorksite.id,
                      {
                        worksite: siteWorksite,
                        config: {
                          isRaksanappiSelected: selectedWorksiteIds.includes(
                            siteWorksite.id
                          ),
                        },
                      },
                    ];
                  })
                );

                return [
                  siteId,
                  {
                    site,
                    config: {
                      isRaksanappiSelected: Object.values(worksites).every(
                        (worksite) => worksite.config.isRaksanappiSelected
                      ),
                    },
                    worksites,
                  },
                ];
              }
            )
          );

        return [
          customerId,
          {
            customer,
            config: {
              isRaksanappiSelected: _.values(sites).every(
                (site) => site.config.isRaksanappiSelected
              ),
            },
            sites,
          },
        ];
      }
    )
  );
};

const buildUserInnerFormValues = (
  user: null | User,
  ynetCustomers?: InnerFormYnetCustomers,
  omaltCustomers?: InnerFormOmaltCustomers,
  raksanappiCustomers?: InnerFormRaksanappiCustomers
) => {
  return {
    auth0Id: user?.id,
    personalInfo: {
      email: user?.personalInformation?.email || "",
      firstName: user?.personalInformation?.firstName || "",
      lastName: user?.personalInformation?.lastName || "",
      phoneNumber: user?.personalInformation?.phone || "",
      isDemoUser: user?.personalInformation?.isDemoUser || false,
    },
    ymparistonetti: {
      customers: ynetCustomers || {},
      emailInstructions: user?.id
        ? EmailInstructions.ExistingUser
        : EmailInstructions.NewUser,
      premiumSubscription:
        user?.ynet?.isPremium || ynetConfig.initialPremiumSubscription,
      role: user?.ynet?.role || ynetConfig.initialRole,
      sendEmailInstructions: false,
      termsAccepted: user?.ynet?.termsAccepted,
      webNotifications: user?.ynet?.webNotifications ?? true,
    },
    omalt: {
      customers: omaltCustomers || {},
      emailInstructions: user?.id
        ? EmailInstructions.ExistingUser
        : EmailInstructions.NewUser,
      billingAccess:
        user?.omalt?.hasBillingAccess || omaltConfig.initialBillingAccess,
      role: user?.omalt?.role || omaltConfig.initialRole,
      sendEmailInstructions: false,
      termsAccepted: user?.omalt?.termsAccepted,
      hasSeenIntroductionPopUp: user?.omalt?.hasSeenIntroductionPopUp ?? false,
    },
    raksanappi: {
      customers: raksanappiCustomers || {},
      emailInstructions: EmailInstructions.NewUser,
      role: user?.raksanappi.role || raksanappiConfig.initialRole,
      sendEmailInstructions: false,
      terms: user?.raksanappi.terms,
      hasAccessToReports:
        user?.raksanappi.hasAccessToReports ||
        raksanappiConfig.initialHasAccessToReports,
    },
    kiinteistonetti: {
      emailInstructions: EmailInstructions.NewUser,
      role: user?.kiinteistonetti?.role || kiinteistonettiConfig.initialRole,
      sendEmailInstructions: false,
      customers: user?.kiinteistonetti?.customers || [],
    },
  };
};

const getYnetSelectedWorksiteIds = (
  ynetConfig: YnetConfig & { worksites: Worksite[] }
): Worksite["id"][] => {
  if ([YnetRole.NoAccess, YnetRole.Customer].includes(ynetConfig.role)) {
    return ynetConfig.partialAccessCustomers.flatMap(
      (partialAccessCustomer) => partialAccessCustomer.worksites
    );
  }
  if (YnetRole.Admin === ynetConfig.role) {
    return ynetConfig.worksites.map((worksite) => worksite.id);
  }
  return [];
};

const getOmaltSelectedWorksiteIds = (
  omaltConfig: OmaLtConfig & { worksites: Worksite[] }
): Worksite["id"][] => {
  if ([OmaltRole.NoAccess, OmaltRole.Customer].includes(omaltConfig.role)) {
    return omaltConfig.partialAccessCustomers.flatMap(
      (partialAccessCustomer) => partialAccessCustomer.worksites
    );
  }
  if (OmaltRole.Admin === omaltConfig.role) {
    return omaltConfig.worksites.map((worksite) => worksite.id);
  }
  return [];
};

const getRaksanappiSelectedWorksiteIds = (
  raksanappiData: RaksanappiResponseData
): Worksite["id"][] => {
  return (
    raksanappiData.partialAccessCustomers
      ?.map((customer) => customer.worksites)
      .flat() || []
  );
};

export const mapGetUserResponseToUserInnerFormValues = (
  user: null | User
): UserInnerFormValues => {
  if (!user) {
    return buildUserInnerFormValues(user);
  }

  const ynetAndOmaltWorksites = _.uniqBy(
    [...user.ynet.worksites, ...user.omalt.worksites],
    "id"
  );

  const ynetSelectedWorksiteIds = getYnetSelectedWorksiteIds(user.ynet);
  const ynetCustomers = createYnetCustomers(
    ynetAndOmaltWorksites,
    ynetSelectedWorksiteIds
  );

  const omaltSelectedWorksiteIds = getOmaltSelectedWorksiteIds(user.omalt);
  const omaltCustomers = createOmaltCustomers(
    ynetAndOmaltWorksites,
    omaltSelectedWorksiteIds
  );

  const raksanappiSelectedWorksiteIds = getRaksanappiSelectedWorksiteIds(
    user.raksanappi
  );
  const raksanappiCustomers = createRaksanappiCustomers(
    user.raksanappi.worksites,
    raksanappiSelectedWorksiteIds
  );

  return buildUserInnerFormValues(
    user,
    ynetCustomers,
    omaltCustomers,
    raksanappiCustomers
  );
};

export const mapPropsToValues = ({
  user,
}: UserFormProps & UserInnerFormProps): UserInnerFormValues => {
  return mapGetUserResponseToUserInnerFormValues(user);
};
