/* eslint-disable no-template-curly-in-string */
import { IntlShape } from "react-intl";
import * as Yup from "yup";
import gPhoneNumber from "google-libphonenumber";
import validateEmailFactory from "./validateEmailFactory";
import {
  YnetRole,
  OmaltRole,
  RaksanappiRole,
} from "new-design/utils/backendTypes";
import { EmailInstructions } from "new-design/utils/types";

declare module "yup" {
  export interface StringSchema {
    phone(errorMessage?: string, countryCode?: string): StringSchema;
  }
}

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

const finlandCountryCode = "FI";

Yup.addMethod(
  Yup.string,
  "phone",
  function yupPhone(
    errorMessage: string = "",
    countryCode: string = finlandCountryCode
  ) {
    return this.test("phone", errorMessage, (value: string | undefined) => {
      try {
        if (!value) {
          return true;
        }

        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false;
        }

        const regionCodeFromPhoneNumber =
          phoneUtil.getRegionCodeForNumber(phoneNumber);

        return phoneUtil.isValidNumberForRegion(
          phoneNumber,
          regionCodeFromPhoneNumber
        );
      } catch {
        return false;
      }
    });
  }
);

export const getValidationSchema =
  (emailExists: (email: string) => Promise<boolean>) =>
  (intl: IntlShape, isNewUser: boolean = false) => {
    const emailValidationMessages = {
      userAlreadyExists: intl.formatMessage({
        id: "user-form.validation.email.user-already-exists",
      }),
      userSearchFailed: intl.formatMessage({
        id: "user-form.validation.email.user-search-failed",
      }),
      checkingEmailInProgress: intl.formatMessage({
        id: "user-form.validation.email.check-in-progress",
      }),
    };
    const validateEmail = validateEmailFactory(
      emailValidationMessages,
      "personalInfo.email",
      emailExists
    );

    return Yup.object({
      personalInfo: Yup.object()
        .shape({
          email: Yup.string()
            .email(
              intl.formatMessage({ id: "user-form.validation.email.invalid" })
            )
            .test({
              name: "duplicate email",
              message: emailValidationMessages.userAlreadyExists,
              test: isNewUser ? validateEmail : () => true,
            })
            .required(
              intl.formatMessage({ id: "user-form.validation.email.required" })
            ),
          firstName: Yup.string().required(
            intl.formatMessage({
              id: "user-form.validation.first-name.required",
            })
          ),
          lastName: Yup.string().required(
            intl.formatMessage({
              id: "user-form.validation.last-name.required",
            })
          ),
          phoneNumber: Yup.string().phone(
            intl.formatMessage({
              id: "user-form.validation.phone-number.invalid",
            })
          ),
        })
        .when("raksanappi.role", {
          is: (val: RaksanappiRole) => val === RaksanappiRole.Customer,
          then: Yup.object().shape({
            phoneNumber: Yup.string()
              .required(
                intl.formatMessage({
                  id: "user-form.validation.phone-number.required",
                })
              )
              .phone(
                intl.formatMessage({
                  id: "user-form.validation.phone-number.invalid",
                })
              ),
          }),
        }),
      ymparistonetti: Yup.object().shape({
        role: Yup.string()
          .oneOf([YnetRole.Admin, YnetRole.Customer, YnetRole.NoAccess])
          .required(),
        sendEmailInstructions: Yup.bool().required(),
        emailInstructions: Yup.string()
          .oneOf([EmailInstructions.NewUser, EmailInstructions.ExistingUser])
          .required(),
      }),
      omalt: Yup.object().shape({
        role: Yup.string()
          .oneOf([OmaltRole.Admin, OmaltRole.Customer, OmaltRole.NoAccess])
          .required(),
        sendEmailInstructions: Yup.bool().required(),
        emailInstructions: Yup.string()
          .oneOf([EmailInstructions.NewUser, EmailInstructions.ExistingUser])
          .required(),
      }),
      raksanappi: Yup.object().shape({
        role: Yup.string()
          .oneOf([RaksanappiRole.NoAccess, RaksanappiRole.Customer])
          .required(),
        emailInstructions: Yup.string()
          .oneOf([EmailInstructions.NewUser])
          .required(),
      }),
    });
  };
