import _ from "lodash";
import { EmailInstructions } from "new-design/utils/types";
import {
  ModifyUserPayload,
  YnetRole,
  OmaltRole,
  PartialAccessCustomer,
} from "new-design/utils/backendTypes";
import { UserInnerFormValues } from "./UserForm";
import {
  InnerFormOmaltCustomers,
  InnerFormYnetCustomers,
} from "./mapPropsToValues";

const getYnetPartialAccessCustomers = (
  customers: InnerFormYnetCustomers
): PartialAccessCustomer[] => {
  return Object.values(customers)
    .map((customer) => ({
      customer: customer.customer.id,
      worksites: Object.values(customer.sites).flatMap((site) => {
        return Object.values(site.worksites)
          .filter((worksite) => worksite.config.isYnetSelected)
          .map((worksite) => worksite.worksite.id);
      }),
    }))
    .filter((partialAccessCustomer) => partialAccessCustomer.worksites.length);
};

const getOmaltPartialAccessCustomers = (
  customers: InnerFormOmaltCustomers
): PartialAccessCustomer[] => {
  return Object.values(customers)
    .map((customer) => ({
      customer: customer.customer.id,
      worksites: Object.values(customer.sites).flatMap((site) => {
        return Object.values(site.worksites)
          .filter((worksite) => worksite.config.isOmaltSelected)
          .map((worksite) => worksite.worksite.id);
      }),
    }))
    .filter((partialAccessCustomer) => partialAccessCustomer.worksites.length);
};

export const getModifyUserPayload = (
  {
    auth0Id,
    personalInfo: { firstName, lastName, email, phoneNumber, isDemoUser },
    raksanappi,
    ymparistonetti,
    omalt,
    kiinteistonetti,
  }: UserInnerFormValues,
  actorId: string
): ModifyUserPayload => {
  const allowYnetFullAccessCustomers = YnetRole.Admin === ymparistonetti.role;
  const allowYnetPartialAccessCustomers =
    YnetRole.Customer === ymparistonetti.role;

  const allowOmaltFullAccessCustomers = OmaltRole.Admin === omalt.role;
  const allowOmaltPartialAccessCustomers = OmaltRole.Customer === omalt.role;

  return {
    actor: actorId,
    auth0Id,
    personalInformation: {
      firstName,
      lastName,
      email,
      phone: _.isEmpty(phoneNumber) ? undefined : phoneNumber,
      isDemoUser,
    },
    ynetDTO: {
      config: {
        isPremium: ymparistonetti.premiumSubscription,
        role: ymparistonetti.role,
        fullAccessCustomers: allowYnetFullAccessCustomers
          ? Object.values(ymparistonetti.customers)
              .filter((customer) => customer.config.isYnetSelected)
              .map((customer) => customer.customer.id)
          : [],
        partialAccessCustomers: allowYnetPartialAccessCustomers
          ? getYnetPartialAccessCustomers(ymparistonetti.customers)
          : [],
        termsAccepted: ymparistonetti.termsAccepted,
        webNotifications: ymparistonetti.webNotifications,
      },
      sendExistingUserWelcomeEmail:
        ymparistonetti.sendEmailInstructions &&
        ymparistonetti.emailInstructions === EmailInstructions.ExistingUser,
      sendNewUserWelcomeEmail:
        ymparistonetti.sendEmailInstructions &&
        ymparistonetti.emailInstructions === EmailInstructions.NewUser,
    },
    omaDTO: {
      config: {
        termsAccepted: omalt.termsAccepted,
        hasBillingAccess: omalt.billingAccess,
        role: omalt.role,
        fullAccessCustomers: allowOmaltFullAccessCustomers
          ? Object.values(omalt.customers)
              .filter((customer) => customer.config.isOmaltSelected)
              .map((customer) => customer.customer.id)
          : [],
        partialAccessCustomers: allowOmaltPartialAccessCustomers
          ? getOmaltPartialAccessCustomers(omalt.customers)
          : [],
        hasSeenIntroductionPopUp: omalt.hasSeenIntroductionPopUp,
      },
      sendExistingUserWelcomeEmail:
        omalt.sendEmailInstructions &&
        omalt.emailInstructions === EmailInstructions.ExistingUser,
      sendNewUserWelcomeEmail:
        omalt.sendEmailInstructions &&
        omalt.emailInstructions === EmailInstructions.NewUser,
    },
    raksanappiDTO: {
      config: {
        role: raksanappi.role,
        terms: raksanappi?.terms,
        hasAccessToReports: raksanappi.hasAccessToReports,
        partialAccessCustomers: Object.values(raksanappi.customers)
          .flatMap((customer) => {
            return {
              customer: customer.customer.id,
              worksites: Object.values(customer.sites).flatMap((site) => {
                return Object.values(site.worksites)
                  .filter((worksite) => worksite.config.isRaksanappiSelected)
                  .map((worksite) => worksite.worksite.id);
              }),
            };
          })
          .filter(
            (partialAccessCustomer) => partialAccessCustomer.worksites.length
          ),
      },
      sendNewUserWelcomeEmail:
        raksanappi.sendEmailInstructions &&
        raksanappi.emailInstructions === EmailInstructions.NewUser,
    },
    kiinteistonettiDTO: {
      config: {
        customers:
          kiinteistonetti.customers.map((customer) => customer.id) || [],
        role: kiinteistonetti.role,
      },
      sendNewUserWelcomeEmail:
        kiinteistonetti.sendEmailInstructions &&
        kiinteistonetti.emailInstructions === EmailInstructions.NewUser,
    },
  };
};
