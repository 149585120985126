import { UserConfig, UserSearchResponse } from "new-design/utils/backendTypes";
import { get } from "utils/api";
import _ from "lodash";

enum FetchUsersActionType {
  Request = "request",
  Success = "success",
  Failure = "failure",
  Reset = "reset",
}

type FetchUsersAction = {
  type: FetchUsersActionType;
  users?: UserConfig[];
  errors?: string[];
};

export type FetchUsersState = {
  loading: boolean;
  users: UserConfig[];
  errors: null | string[];
};

export const initialState: FetchUsersState = {
  loading: false,
  errors: null,
  users: [],
};

export const reducer = (
  state: FetchUsersState,
  action: FetchUsersAction
): FetchUsersState => {
  const newState = {
    [FetchUsersActionType.Request]: {
      loading: true,
    },
    [FetchUsersActionType.Success]: {
      loading: false,
      users: action.users,
    },
    [FetchUsersActionType.Failure]: {
      loading: false,
      errors: action.errors,
    },
    [FetchUsersActionType.Reset]: initialState,
  };

  return Object.assign({}, state, newState[action.type]);
};

type fetchUserProps = {
  query: string;
  dispatch: React.Dispatch<FetchUsersAction>;
};

export const fetchUsers = _.debounce(
  async ({ query, dispatch }: fetchUserProps) => {
    dispatch({ type: FetchUsersActionType.Request });
    try {
      const response = await get(`/v2/users?query=${query}&pageSize=10&page=0`);
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const body = isJson
        ? ((await response.json()) as UserSearchResponse)
        : undefined;

      if (!response.ok) {
        return dispatch({
          type: FetchUsersActionType.Failure,
          errors: [response.statusText],
        });
      }

      dispatch({ type: FetchUsersActionType.Success, users: body?.users });
    } catch (error: any) {
      if (error instanceof Object && error.hasOwnProperty("errors")) {
        dispatch({
          type: FetchUsersActionType.Failure,
          errors: error.errors,
        });
      }
    }
  },
  500
);
