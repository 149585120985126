import { CustomerBase, Worksite } from "new-design/utils/types";
import { get } from "utils/api";
import _ from "lodash";

enum FetchWorksitesActionType {
  Request = "request",
  Success = "success",
  Failure = "failure",
  Reset = "reset",
}

type FetchWorksitesAction = {
  type: FetchWorksitesActionType;
  worksites?: Worksite[];
  customers?: CustomerBase[];
  error?: string;
};

export type FetchWorksitesAndCustomersState = {
  loading: boolean;
  error: null | string;
  worksites: Worksite[];
  customers: CustomerBase[];
};

export const initialState: FetchWorksitesAndCustomersState = {
  loading: false,
  error: null,
  worksites: [],
  customers: [],
};

export const reducer = (
  state: FetchWorksitesAndCustomersState,
  action: FetchWorksitesAction
): FetchWorksitesAndCustomersState => {
  const newState = {
    [FetchWorksitesActionType.Request]: {
      loading: true,
    },
    [FetchWorksitesActionType.Success]: {
      loading: false,
      worksites: action.worksites,
      customers: action.customers,
    },
    [FetchWorksitesActionType.Failure]: {
      loading: false,
      error: action.error,
    },
    [FetchWorksitesActionType.Reset]: initialState,
  };

  return Object.assign({}, state, newState[action.type]);
};

type fetchWorksitesAndCustomersProps = {
  searchQuery: string;
  includeInactive: boolean;
  dispatch: React.Dispatch<FetchWorksitesAction>;
  omitWorksites?: Worksite["id"][];
};

let controller: AbortController | undefined;

export const fetchWorksitesAndCustomers = _.debounce(
  ({
    searchQuery,
    includeInactive,
    dispatch,
    omitWorksites = [],
  }: fetchWorksitesAndCustomersProps) => {
    dispatch({ type: FetchWorksitesActionType.Request });
    try {
      controller && controller.abort();
      controller = new AbortController();
      get(
        `/access/search?query=${searchQuery}&includeInactive=${includeInactive}`,
        controller
      ).then(async (response) => {
        controller = undefined;
        const body = (await response.json()) as {
          worksites: Worksite[];
          customers: CustomerBase[];
        };

        dispatch({
          type: FetchWorksitesActionType.Success,
          worksites: body.worksites.filter(
            (worksite) => !omitWorksites.includes(worksite.id)
          ),
          customers: body.customers,
        });
      });
    } catch (error) {
      controller = undefined;
      dispatch({
        type: FetchWorksitesActionType.Failure,
        error: "",
      });
    }
  },
  500
);
