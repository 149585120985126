import { Customer, Worksite } from "./types";

export enum YnetRole {
  NoAccess = "NoAccess",
  Customer = "Customer",
  Admin = "Admin",
}

export enum OmaltRole {
  NoAccess = "NoAccess",
  Customer = "Customer",
  Admin = "Admin",
}

export enum RaksanappiRole {
  Customer = "Customer",
  NoAccess = "NoAccess",
}

export enum KiinteistonettiRole {
  // Admin = 'Admin',
  Customer = "Customer",
  NotUser = "NotUser",
}

export type KiinteistonettiResponseData = {
  role: KiinteistonettiRole;
  customers: Customer[];
};

export type RaksanappiConfig = {
  role: RaksanappiRole;
  hasAccessToReports: boolean;
  terms?: Date;
  partialAccessCustomers: PartialAccessCustomer[];
};

export type RaksanappiResponseData = {
  role: RaksanappiRole;
  partialAccessCustomers: PartialAccessCustomer[];
  hasAccessToReports: boolean;
  terms?: Date;
};

export type UserSearchResponse = {
  page: number;
  pageSize: number;
  total: number;
  users: UserConfig[];
};

export type UserSearchResultRow = {
  user_id: string;
  name: string;
  email: string;
};

export type RaksanappiDTO = {
  config: RaksanappiConfig;
  sendNewUserWelcomeEmail: boolean;
};

export type PartialAccessCustomer = {
  customer: Customer["id"];
  worksites: Worksite["id"][];
};

export type UserPersonalInformation = {
  createdAt?: string;
  email: string;
  firstName: string;
  isDemoUser: boolean;
  lastName: string;
  phone?: string;
};

export type YnetConfig = {
  fullAccessCustomers: Customer["id"][];
  isPremium: boolean;
  partialAccessCustomers: PartialAccessCustomer[];
  termsAccepted: string | undefined;
  role: YnetRole;
  webNotifications: boolean;
};

export type YnetRequestDTO = {
  config: YnetConfig;
  sendNewUserWelcomeEmail: boolean;
  sendExistingUserWelcomeEmail: boolean;
};

export type OmaLtConfig = {
  fullAccessCustomers: Customer["id"][];
  hasBillingAccess: boolean;
  partialAccessCustomers: PartialAccessCustomer[];
  role: OmaltRole;
  termsAccepted?: string;
  hasSeenIntroductionPopUp: boolean;
};

export type OmaLtRequestDTO = {
  config: OmaLtConfig;
  sendNewUserWelcomeEmail: boolean;
  sendExistingUserWelcomeEmail: boolean;
};

export type KiinteistonettiConfig = {
  customers: Customer["id"][];
  role: string;
};

export type KiinteistonettiRequestDTO = {
  config: KiinteistonettiConfig;
  sendNewUserWelcomeEmail?: boolean;
};

export type ModifyUserPayload = {
  actor: string;
  auth0Id?: string;
  personalInformation: UserPersonalInformation;
  raksanappiDTO: RaksanappiDTO;
  ynetDTO: YnetRequestDTO;
  omaDTO: OmaLtRequestDTO;
  kiinteistonettiDTO: KiinteistonettiRequestDTO;
};

export type GetUserResponse = {
  id: string;
  personalInformation: UserPersonalInformation;
  ynet: YnetConfig;
  omalt: OmaLtConfig;
  raksanappi: RaksanappiResponseData;
  kiinteistonetti: KiinteistonettiResponseData;
};

export type CheckEmailRequest = {
  email: string;
};

export type CheckEmailResponse = {
  exists: boolean;
};

export type UserConfig = {
  userId: string;
  kiinteistonettiConfig: KiinteistonettiConfig;
  userPersonalInformation: UserPersonalInformation;
  raksanappiConfig: RaksanappiConfig;
  omaLTConfig: OmaLtConfig;
  ynetConfig: YnetConfig;
};
