import React, {
  MouseEventHandler,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Field, FieldProps } from "formik";
import _ from "lodash";
import {
  CustomerItem,
  CustomerList,
  CustomerListHeader,
  InputCheckboxLabel,
  PreviewEmailTemplateButton,
  TypeaheadLabel,
  CustomerWorksiteExpandButton,
} from "./styled";
import { omaltConfig, ynetConfig } from "new-design/utils/constants";
import {
  Dropdown,
  InputCheckbox,
  InputRadioGroup,
  Typeahead,
  Icon,
  IconSize,
  ThemeType,
} from "@kettu/ui-components";
import { useTheme } from "styled-components";
import { UserFormTranslationsType } from "./UserForm.translations";
import {
  CustomerBase,
  Customer,
  Worksite,
  EmailInstructions,
} from "new-design/utils/types";
import { YnetRole, OmaltRole } from "new-design/utils/backendTypes";
import { UserInnerFormValues } from "./UserForm";
import {
  fetchWorksitesAndCustomers,
  initialState as worksitesInitialState,
  reducer as worksitesReducer,
} from "./UserForm.worksites";
import { useFormattedMessage } from "../../utils/useFormattedMessage.hook";
import { CustomerWorksites } from "./CustomerWorksites";
import { get } from "utils/api";
import {
  createOmaltCustomers,
  createYnetCustomers,
  getOmaltSelectedWorksites,
  getYnetSelectedWorksites,
  omaltCustomersMapperFn,
  omaltSitesMapperFn,
  omaltWorksitesMapperFn,
  ynetCustomersMapperFn,
  ynetSitesMapperFn,
  ynetWorksitesMapperFn,
} from "./mapPropsToValues";

type UserFormEnvNetworkProps = {
  translations: UserFormTranslationsType;
  setFieldValue: (field: string, value: any) => void;
  handlePreviewEmailTemplateClick: (
    serviceId: string,
    emailInstruction: EmailInstructions
  ) => MouseEventHandler<HTMLButtonElement> | undefined;
  setEmailPreviewElement: (a: any) => void;
  values: UserInnerFormValues;
};

export const isWorksite = (obj: any): obj is Worksite => {
  return (
    obj.address !== undefined &&
    obj.site !== undefined &&
    obj.site.customer !== undefined
  );
};

export async function fetchWorksitesForCustomer(customer: CustomerBase) {
  const response = await get(`/customers/${customer.id}/worksites`);
  const isJson = response.headers
    .get("content-type")
    ?.includes("application/json");
  const worksites = (isJson ? await response.json() : []) as Worksite[];
  return { customer, worksites };
}

export const xor = (a: boolean, b: boolean) => {
  return (a || b) && !(a && b);
};

export const UserFormEnvNetwork = ({
  values,
  translations,
  setFieldValue,
  handlePreviewEmailTemplateClick,
}: UserFormEnvNetworkProps) => {
  const theme = useTheme() as ThemeType;

  const typeaheadLoadingText = useFormattedMessage(
    "user-form.environmental-network.typeahead-loading-text"
  );

  const [customerWorksitesExpanded, setCustomerWorksitesExpanded] = useState<
    Customer["id"][]
  >([]);

  const ynetAndOmaltCustomers = useMemo(() => {
    const customers = Object.values(
      _.merge({}, values.ymparistonetti.customers, values.omalt.customers)
    ).map((customer) => {
      const sites = Object.values(customer.sites).map((site) => {
        const worksites = _.values(site.worksites).map((worksite) => {
          return {
            worksite: worksite.worksite,
            config: {
              isYnetSelected: worksite.config.isYnetSelected || false,
              isOmaltSelected: worksite.config.isOmaltSelected || false,
            },
          };
        });
        return {
          site: site.site,
          config: {
            isYnetSelected: Object.values(worksites).every(
              (worksite) => worksite.config.isYnetSelected
            ),
            isOmaltSelected: Object.values(worksites).every(
              (worksite) => worksite.config.isOmaltSelected
            ),
          },
          worksites: _.sortBy(worksites, ["worksite.name", "worksite.id"]),
        };
      });

      return {
        customer: customer.customer,
        config: {
          isYnetSelected: Object.values(sites).every(
            (site) => site.config.isYnetSelected
          ),
          isOmaltSelected: Object.values(sites).every(
            (site) => site.config.isOmaltSelected
          ),
        },
        sites: _.sortBy(sites, ["site.name", "site.id"]),
      };
    });

    return _.sortBy(customers, ["customer.name", "customer.id"]);
  }, [values.ymparistonetti.customers, values.omalt.customers]);

  const [worksitesAndCustomersState, worksitesAndCustomersDispatcher] =
    useReducer(worksitesReducer, worksitesInitialState);

  const filterOptions = useCallback(
    ({
      value: worksite,
    }: {
      label: string;
      value: { id: Worksite["id"]; name: Worksite["name"] };
    }) => {
      return true;
    },
    [values.ymparistonetti.customers, values.omalt.customers]
  );

  const typeaheadOptions = useMemo(() => {
    const worksitesByCustomers = _.chain(worksitesAndCustomersState.worksites)
      .groupBy((worksite) => worksite.site.customer.id)
      .flatMap((worksites) => {
        const worksite = worksites[0];
        const customer = worksite.site.customer;

        const customerOption = {
          index: customer.name,
          label: customer.name,
          value: customer,
        };
        const worksiteOptions = worksites.map((worksite) => {
          const optionLabel =
            worksite.name === customer.name
              ? `    ${worksite.name} ${worksite.address.street}`
              : `    ${worksite.name}`;

          return {
            index: `${customer.name} ${worksite.name}`,
            label: optionLabel,
            value: worksite,
          };
        });

        return [customerOption, ...worksiteOptions];
      })
      .value();

    return _.sortBy(
      [
        ...worksitesAndCustomersState.customers.map((customer) => ({
          index: customer.name,
          label: customer.name,
          value: customer,
        })),
        ...worksitesByCustomers,
      ],
      ["index"]
    );
  }, [
    values.ymparistonetti.customers,
    values.omalt.customers,
    worksitesAndCustomersState,
    filterOptions,
  ]);

  const handleCustomerWorksiteExpandButtonClick = useCallback(
    (customerId: Customer["id"]) => {
      if (customerWorksitesExpanded.includes(customerId)) {
        return setCustomerWorksitesExpanded(
          customerWorksitesExpanded.filter((id) => id !== customerId)
        );
      }
      setCustomerWorksitesExpanded([...customerWorksitesExpanded, customerId]);
    },
    [customerWorksitesExpanded, setCustomerWorksitesExpanded]
  );

  const handleTypeaheadChange = useCallback(
    (searchQuery: any) =>
      !!searchQuery &&
      searchQuery.length &&
      fetchWorksitesAndCustomers({
        includeInactive: true,
        dispatch: worksitesAndCustomersDispatcher,
        searchQuery,
      }),
    [worksitesAndCustomersDispatcher]
  );

  const handleTypeAheadSelect = useCallback(
    async (value: Worksite | CustomerBase | null) => {
      if (!value) {
        return;
      }

      const customer = isWorksite(value) ? value.site.customer : value;

      try {
        const { worksites } = await fetchWorksitesForCustomer(customer);

        const ynetSelectedWorksites = values.ymparistonetti.role !== YnetRole.NoAccess
          ? isWorksite(value)
          ? values.ymparistonetti.role === YnetRole.Customer
            ? [
                ...getYnetSelectedWorksites(values.ymparistonetti.customers),
                value.id,
              ]
            : values.ymparistonetti.role === YnetRole.Admin
            ? worksites.map((worksite) => worksite.id)
            : []
          : worksites.map((worksite) => worksite.id)
          : [];

        setFieldValue("ymparistonetti.customers", {
          ...values.ymparistonetti.customers,
          ...createYnetCustomers(worksites, ynetSelectedWorksites),
        });

        const omaltSelectedWorksites = values.omalt.role !== OmaltRole.NoAccess
          ? isWorksite(value)
          ? values.omalt.role === OmaltRole.Customer
            ? [...getOmaltSelectedWorksites(values.omalt.customers), value.id]
            : values.omalt.role === OmaltRole.Admin
            ? worksites.map((worksite) => worksite.id)
            : []
          : worksites.map((worksite) => worksite.id)
          : [];

        setFieldValue("omalt.customers", {
          ...values.omalt.customers,
          ...createOmaltCustomers(worksites, omaltSelectedWorksites),
        });

        if (
          isWorksite(value) &&
          !customerWorksitesExpanded.includes(customer.id)
        ) {
          handleCustomerWorksiteExpandButtonClick(customer.id);
        }
      } catch {}
    },
    [
      setFieldValue,
      values.ymparistonetti.role,
      values.ymparistonetti.customers,
      values.omalt.role,
      values.omalt.customers,
      customerWorksitesExpanded,
      handleCustomerWorksiteExpandButtonClick,
    ]
  );

  const handleYmparistonettiCustomerChangeFactory = useCallback(
    (customerId: Customer["id"]) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(
          "ymparistonetti.customers",
          ynetCustomersMapperFn(
            values.ymparistonetti.customers,
            (customer) => customer.customer.id === customerId,
            (sites) => {
              return ynetSitesMapperFn(
                sites,
                () => true,
                (worksites) => {
                  return ynetWorksitesMapperFn(
                    worksites,
                    () => true,
                    (worksite) => {
                      return {
                        worksite: worksite.worksite,
                        config: { isYnetSelected: event.target.checked },
                      };
                    }
                  );
                }
              );
            }
          )
        );
      },
    [values.ymparistonetti.customers]
  );

  const handleOmaltCustomerChangeFactory = useCallback(
    (customerId: Customer["id"]) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(
          "omalt.customers",
          omaltCustomersMapperFn(
            values.omalt.customers,
            (customer) => customer.customer.id === customerId,
            (sites) => {
              return omaltSitesMapperFn(
                sites,
                () => true,
                (worksites) => {
                  return omaltWorksitesMapperFn(
                    worksites,
                    () => true,
                    (worksite) => {
                      return {
                        worksite: worksite.worksite,
                        config: {
                          isOmaltSelected: event.target.checked,
                        },
                      };
                    }
                  );
                }
              );
            }
          )
        );
      },
    [values.omalt.customers]
  );

  const handleYnetAndOmaltCustomerChange = useCallback(
    (customerId: Customer["id"]) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        handleYmparistonettiCustomerChangeFactory(customerId)(event);
        handleOmaltCustomerChangeFactory(customerId)(event);
      },
    [
      handleOmaltCustomerChangeFactory,
      handleYmparistonettiCustomerChangeFactory,
    ]
  );

  const handleYmparistonettiRoleInputChange = useCallback(
    async (value) => {
      const allWorksites = Object.entries(
        values.ymparistonetti.customers
      ).flatMap(([, customer]) => {
        return Object.entries(customer.sites).flatMap(([, site]) => {
          return Object.entries(site.worksites).map(([, worksite]) => worksite);
        });
      });

      setFieldValue(
        "ymparistonetti.customers",
        ynetCustomersMapperFn(
          values.ymparistonetti.customers,
          () => true,
          (sites) => {
            return ynetSitesMapperFn(
              sites,
              () => true,
              (worksites) => {
                return ynetWorksitesMapperFn(
                  worksites,
                  () => true,
                  (worksite) => {
                    return {
                      worksite: worksite.worksite,
                      config: {
                        isYnetSelected:
                          value === YnetRole.NoAccess
                            ? false
                            : value === YnetRole.Admin
                            ? allWorksites.some(
                                (worksite) => worksite.config.isYnetSelected
                              )
                            : worksite.config.isYnetSelected,
                      },
                    };
                  }
                );
              }
            );
          }
        )
      );

      setFieldValue("ymparistonetti.role", value);
    },
    [values.ymparistonetti.customers, values.ymparistonetti.role]
  );

  const handleOmaltRoleInputChange = useCallback(
    async (value) => {
      const allWorksites = Object.entries(values.omalt.customers).flatMap(
        ([, customer]) => {
          return Object.entries(customer.sites).flatMap(([, site]) => {
            return Object.entries(site.worksites).map(
              ([, worksite]) => worksite
            );
          });
        }
      );

      setFieldValue(
        "omalt.customers",
        omaltCustomersMapperFn(
          values.omalt.customers,
          () => true,
          (sites) => {
            return omaltSitesMapperFn(
              sites,
              (site) => true,
              (worksites) => {
                return omaltWorksitesMapperFn(
                  worksites,
                  () => true,
                  (worksite) => {
                    return {
                      worksite: worksite.worksite,
                      config: {
                        isOmaltSelected:
                          value === OmaltRole.NoAccess
                            ? false
                            : value === OmaltRole.Admin
                            ? allWorksites.some(
                                (worksite) => worksite.config.isOmaltSelected
                              )
                            : worksite.config.isOmaltSelected,
                      },
                    };
                  }
                );
              }
            );
          }
        )
      );

      // note: access to billing details only for admin users
      if (value !== OmaltRole.Admin) {
        setFieldValue("omalt.billingAccess", false);
      }
      setFieldValue("omalt.role", value);
    },
    [values.omalt.customers, values.omalt.role, setFieldValue]
  );

  // ---

  const renderYmparistonettiRoleInput = useCallback(
    (props: FieldProps<string, UserInnerFormValues>) => (
      <InputRadioGroup
        {...props.field}
        options={ynetConfig.roles}
        onChange={handleYmparistonettiRoleInputChange}
      />
    ),
    [values.ymparistonetti.role, handleYmparistonettiRoleInputChange]
  );

  const renderYmparistonettiPremiumSubscriptionInput = useCallback(
    (props: FieldProps<boolean, UserInnerFormValues>) => (
      <InputCheckbox
        {...props.field}
        id="ymparistonetti-premium-subscription"
        label={
          <InputCheckboxLabel>
            {translations.ymparistonettiPremiumSubscriptionCheckboxLabel}
          </InputCheckboxLabel>
        }
      />
    ),
    [values.ymparistonetti.premiumSubscription]
  );

  const renderYmparistonettiSendEmailInstructionsInput = useCallback(
    (props: FieldProps<boolean, UserInnerFormValues>) => (
      <InputCheckbox
        {...props.field}
        id="ymparistonetti-send-email-instructions"
        label={
          <InputCheckboxLabel>
            {translations.ymparistonettiEmailInstructionsCheckboxLabel}
          </InputCheckboxLabel>
        }
      />
    ),
    [values.ymparistonetti.sendEmailInstructions]
  );

  const renderYmparistonettiEmailInstructionsDropdown = useCallback(
    ({
      field: { onChange, value, ...field },
      meta,
      ...props
    }: FieldProps<EmailInstructions, UserInnerFormValues>) => (
      <div style={{ flexGrow: 1 }}>
        <Dropdown
          defaultValue={ynetConfig.emailOptions.find(
            (option) => option.value === meta.initialValue
          )}
          id="ymparistonetti.email-instructions"
          label={translations.ymparistonettiEmailInstructionsSelectLabel}
          onChange={(value) =>
            props.form.setFieldValue(field.name, value?.value)
          }
          options={ynetConfig.emailOptions}
          value={ynetConfig.emailOptions.find(
            (option) => option.value === value
          )}
          {...field}
        />
      </div>
    ),
    [values.ymparistonetti.emailInstructions]
  );

  const renderOmaltRoleInput = useCallback(
    (props: FieldProps<OmaltRole, UserInnerFormValues>) => (
      <InputRadioGroup
        {...props.field}
        options={omaltConfig.roles}
        onChange={handleOmaltRoleInputChange}
      />
    ),
    [values.omalt.role, handleOmaltRoleInputChange]
  );

  const renderOmaltBillingAccessCheckbox = useCallback(
    (props: FieldProps<boolean, UserInnerFormValues>) => {
      const omaltRoleMeta = props.form.getFieldMeta<OmaltRole>("omalt.role");
      return (
        <InputCheckbox
          {...props.field}
          id="omalt-billing-role"
          label={
            <InputCheckboxLabel>
              {translations.omaltBillingAccessCheckboxLabel}
            </InputCheckboxLabel>
          }
          disabled={omaltRoleMeta?.value !== OmaltRole.Admin}
        />
      );
    },
    [values.omalt.billingAccess]
  );

  const renderOmaltSendEmailInstructionsCheckbox = useCallback(
    (props: FieldProps<boolean, UserInnerFormValues>) => (
      <InputCheckbox
        {...props.field}
        id="omalt-send-email-instructions"
        label={
          <InputCheckboxLabel>
            {translations.omaltEmailInstructionsCheckboxLabel}
          </InputCheckboxLabel>
        }
      />
    ),
    [values.omalt.sendEmailInstructions]
  );

  const renderOmaltEmailInstructionsDropdown = useCallback(
    ({
      field: { onChange, value, ...field },
      meta,
      ...props
    }: FieldProps<EmailInstructions, UserInnerFormValues>) => (
      <div style={{ flexGrow: 1 }}>
        <Dropdown
          defaultValue={omaltConfig.emailOptions.find(
            (option) => option.value === meta.initialValue
          )}
          id="omalt.email-instructions"
          label={translations.omaltEmailInstructionsSelectLabel}
          onChange={(value) =>
            props.form.setFieldValue(field.name, value?.value)
          }
          options={omaltConfig.emailOptions}
          value={omaltConfig.emailOptions.find(
            (option) => option.value === value
          )}
          {...field}
        />
      </div>
    ),
    [values.omalt.emailInstructions]
  );

  const renderYnetAndOmaltCustomerListHeaders = useMemo(() => {
    return (
      <CustomerListHeader>
        <div style={{ fontSize: "16px", minWidth: "200px" }}>
          <b>Ympäristönetti</b>
          <br />(
          {
            ynetConfig.roles.find(
              (role) => role.value === values.ymparistonetti.role
            )?.label
          }
          )
        </div>
        <div style={{ fontSize: "16px", minWidth: "150px" }}>
          <b>{"Oma L&T"}</b>
          <br />(
          {
            omaltConfig.roles.find((role) => role.value === values.omalt.role)
              ?.label
          }
          )
        </div>
      </CustomerListHeader>
    );
  }, [values.ymparistonetti.role, values.omalt.role]);

  const ynetAndOmaltCustomerList = useMemo(() => {
    return (
      <>
        {ynetAndOmaltCustomers.length > 0 &&
          renderYnetAndOmaltCustomerListHeaders}
        {ynetAndOmaltCustomers.map((customer) => {
          const worksites = customer.sites.flatMap((site) => site.worksites);
          const showCustomersWorksites = customerWorksitesExpanded.includes(
            customer.customer.id
          );
          const isYnetCustomerCheckboxDisabled =
            values.ymparistonetti.role === YnetRole.NoAccess;
          const isOmaltCustomerCheckboxDisabled =
            values.omalt.role === OmaltRole.NoAccess;
          const isCustomerCheckboxDisabled =
            isYnetCustomerCheckboxDisabled || isOmaltCustomerCheckboxDisabled;

          const ynetCustomerCheckboxIndeterminate = worksites.some(
            (worksite) => worksite.config.isYnetSelected
          );
          const omaltCustomerCheckboxIndeterminate = worksites.some(
            (worksite) => worksite.config.isOmaltSelected
          );

          return (
            <div key={customer.customer.id}>
              <CustomerItem>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <InputCheckbox
                    id={`ynet-and-omalt-customer--${customer.customer.id}`}
                    onChange={handleYnetAndOmaltCustomerChange(
                      customer.customer.id
                    )}
                    indeterminate={
                      ynetCustomerCheckboxIndeterminate ||
                      omaltCustomerCheckboxIndeterminate
                    }
                    checked={
                      customer.config.isYnetSelected &&
                      customer.config.isOmaltSelected
                    }
                    label={
                      <span
                        style={{ paddingLeft: "16px", display: "inline-block" }}
                      >
                        <b>{customer.customer.name}</b>
                        <br />
                        {customer.customer.id}
                      </span>
                    }
                    disabled={isCustomerCheckboxDisabled}
                  />
                  <CustomerWorksiteExpandButton
                    onClick={() =>
                      handleCustomerWorksiteExpandButtonClick(
                        customer.customer.id
                      )
                    }
                  >
                    {customerWorksitesExpanded.includes(
                      customer.customer.id
                    ) ? (
                      <Icon
                        icon={theme.icons.arrowDown}
                        size={IconSize.Small}
                      />
                    ) : (
                      <Icon
                        icon={theme.icons.arrowRight}
                        size={IconSize.Small}
                      />
                    )}
                  </CustomerWorksiteExpandButton>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "88px" }}
                >
                  <InputCheckbox
                    id={`ynet-customer--${customer.customer.id}`}
                    indeterminate={ynetCustomerCheckboxIndeterminate}
                    onChange={handleYmparistonettiCustomerChangeFactory(
                      customer.customer.id
                    )}
                    checked={customer.config.isYnetSelected}
                    label={
                      <span style={{ paddingLeft: "14px" }}>
                        {theme.icons.ymparistonetti}
                      </span>
                    }
                    disabled={isYnetCustomerCheckboxDisabled}
                  />
                  <InputCheckbox
                    id={`omalt-customer--${customer.customer.id}`}
                    indeterminate={omaltCustomerCheckboxIndeterminate}
                    onChange={handleOmaltCustomerChangeFactory(
                      customer.customer.id
                    )}
                    checked={customer.config.isOmaltSelected}
                    label={
                      <span style={{ paddingLeft: "14px" }}>
                        {theme.icons.omalt}
                      </span>
                    }
                    disabled={isOmaltCustomerCheckboxDisabled}
                  />
                </div>
              </CustomerItem>
              {showCustomersWorksites && (
                <CustomerWorksites
                  worksites={worksites}
                  values={values}
                  setFieldValue={setFieldValue}
                  ymparistonettiRole={values.ymparistonetti.role}
                  omaltRole={values.omalt.role}
                />
              )}
            </div>
          );
        })}
      </>
    );
  }, [
    renderYnetAndOmaltCustomerListHeaders,
    ynetAndOmaltCustomers,
    customerWorksitesExpanded,
  ]);

  return (
    <>
      <div
        style={{ padding: "38px 0 0 0", minHeight: "200px", display: "flex" }}
      >
        <div style={{ flexGrow: 1, maxWidth: "50%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {theme.icons.ymparistonetti}{" "}
            {translations.ymparistonettiUserAccessLevel}
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              padding: "24px 10px 24px 39px",
              flexWrap: "wrap",
            }}
          >
            <Field
              name="ymparistonetti.role"
              children={renderYmparistonettiRoleInput}
            />
          </div>
          <div style={{ display: "flex", padding: "0 10px 67px 41px" }}>
            <Field
              type="checkbox"
              name="ymparistonetti.premiumSubscription"
              children={renderYmparistonettiPremiumSubscriptionInput}
            />
          </div>
          <div>
            <div style={{ paddingBottom: "17px" }}>
              <Field
                type="checkbox"
                name="ymparistonetti.sendEmailInstructions"
                children={renderYmparistonettiSendEmailInstructionsInput}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "flex-end",
                padding: "0 0 58px 37px",
                width: "450px",
              }}
            >
              <Field
                type="select"
                name="ymparistonetti.emailInstructions"
                children={renderYmparistonettiEmailInstructionsDropdown}
              />
              <PreviewEmailTemplateButton
                onClick={handlePreviewEmailTemplateClick(
                  ynetConfig.id,
                  values.ymparistonetti.emailInstructions
                )}
              >
                {theme.icons.eye}
              </PreviewEmailTemplateButton>
            </div>
          </div>
        </div>
        <div style={{ flexGrow: 1, maxWidth: "50%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {theme.icons.omalt} {translations.omaltUserAccessLevel}
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              padding: "24px 10px 24px 39px",
              flexWrap: "wrap",
            }}
          >
            <Field name="omalt.role" children={renderOmaltRoleInput} />
          </div>
          <div style={{ display: "flex", padding: "0 10px 67px 41px" }}>
            <Field
              type="checkbox"
              name="omalt.billingAccess"
              children={renderOmaltBillingAccessCheckbox}
            />
          </div>
          <div>
            <div style={{ paddingBottom: "17px" }}>
              <Field
                type="checkbox"
                name="omalt.sendEmailInstructions"
                children={renderOmaltSendEmailInstructionsCheckbox}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "flex-end",
                padding: "0 0 58px 37px",
                width: "450px",
              }}
            >
              <Field
                type="select"
                name="omalt.emailInstructions"
                children={renderOmaltEmailInstructionsDropdown}
              />
              <PreviewEmailTemplateButton
                onClick={handlePreviewEmailTemplateClick(
                  omaltConfig.id,
                  values.omalt.emailInstructions
                )}
              >
                {theme.icons.eye}
              </PreviewEmailTemplateButton>
            </div>
          </div>
        </div>
      </div>
      <TypeaheadLabel>{translations.worksiteSearchLabel}</TypeaheadLabel>
      <Typeahead
        doNotShowSelection
        externalFilter={filterOptions}
        isLoading={worksitesAndCustomersState.loading}
        loadingText={typeaheadLoadingText}
        onChange={handleTypeaheadChange}
        onSelect={handleTypeAheadSelect}
        options={typeaheadOptions}
      />
      <CustomerList>{ynetAndOmaltCustomerList}</CustomerList>
    </>
  );
};
