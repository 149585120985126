import React, { useState } from "react";
import { CheckEmailRequest } from "./backendTypes";
import { post } from "utils/api";
import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  isLoading: false,
};
const { useGlobalState } = createGlobalState(initialState);

const checkIfEmailExists = async (email: string): Promise<boolean> => {
  const payload: CheckEmailRequest = { email };
  const response = await post(`/users/email/verify`, payload);

  if (!response.ok) {
    throw new Error("Failed to verify email");
  }

  const body = await response.json();

  return body.exists;
};

export const useVerifyEmail = () => {
  const [isLoading, setIsLoading] = useGlobalState("isLoading");
  const [latestResult, setLatestResult] = useState({
    email: "",
    exists: false,
  });

  const emailExists = async (email: string): Promise<boolean> => {
    if (latestResult.email === email) {
      return latestResult.exists;
    }

    setIsLoading(true);
    try {
      const result = await checkIfEmailExists(email);
      setLatestResult({ email, exists: result });
      return result;
    } catch {
      throw new Error("Failed to verify email");
    } finally {
      setIsLoading(false);
    }
  };
  return { isLoading, emailExists };
};
