import styled, { css } from "styled-components";
import { Justify } from "./Types";

export type RowProps = {
  justify?: Justify;
  gap?: string;
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.gap};
  justify-content: ${(props) => props.justify || Justify.Start};

  ${(props) =>
    typeof props.onClick !== undefined &&
    css`
      cursor: pointer;
    `}
`;
