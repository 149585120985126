export enum LoginStatus {
  LOADING = "loading",
  LOGIN = "login",
  SUCCESS = "success",
  UNAUTHORIZED = "unauthorized",
}

export type LocationState = {
  from: {
    pathname: string;
  };
};
