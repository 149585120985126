interface User {
  name: string;
}

export const getBearerToken = (): User => {
  const localToken = localStorage.getItem("user")!;
  return JSON.parse(localToken);
};

const doRequest = (
  path: string,
  body: any,
  method: "GET" | "POST" | "PATCH",
  controller?: AbortController
) => {
  return fetch(`/api${path}`, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    signal: controller?.signal,
  });
};

export const get = (path: string, controller?: AbortController) =>
  doRequest(path, null, "GET", controller);
export const post = (path: string, body: any) => doRequest(path, body, "POST");
