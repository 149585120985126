import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { createTheme, Themes } from "@kettu/ui-components";
import Landing from "pages/Landing";
import OrderPassword from "pages/OrderPassword";
import ChangePassword from "pages/ChangePassword";
import UnblockUser from "pages/UnblockUser";
import RequireAuth from "auth/RequireAuth";
import LoginPage from "pages/LoginPage";
import AuthProvider from "auth/AuthProvider";

import { CreateUserPage as CreateUserPageNewDesign } from "new-design/pages/CreateUserPage";
import { EditUserPage as EditUserPageNewDesign } from "new-design/pages/EditUserPage";
import { UsersPage as UsersPageNewDesign } from "new-design/pages/Users";

const NotFound = () => <>404</>;

export const userStorageKey = "user";

const theme = createTheme(Themes.Blue);

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route
                path="admin/search"
                element={
                  <RequireAuth>
                    <UsersPageNewDesign />
                  </RequireAuth>
                }
              />
              <Route
                path="admin/user"
                element={
                  <RequireAuth>
                    <CreateUserPageNewDesign />
                  </RequireAuth>
                }
              />
              <Route
                path="admin/user/:id"
                element={
                  <RequireAuth>
                    <EditUserPageNewDesign />
                  </RequireAuth>
                }
              />
              <Route path="login" element={<LoginPage />} />
              <Route path="passwords/reset" element={<OrderPassword />} />
              <Route path="passwords/change" element={<ChangePassword />} />
              <Route path="passwords/unblock" element={<UnblockUser />} />
              <Route path="/" element={<Landing />} />
              <Route element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
