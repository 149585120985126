import React, { useMemo, useCallback } from "react";
import { UserInnerFormValues } from "./UserForm";
import { InputCheckbox } from "@kettu/ui-components";
import { CustomerWorksiteItem } from "./styled";
import { useTheme } from "styled-components";
import {
  omaltCustomersMapperFn,
  InnerFormOmaltWorksite,
  InnerFormYnetWorksite,
  omaltSitesMapperFn,
  omaltWorksitesMapperFn,
  ynetCustomersMapperFn,
  ynetSitesMapperFn,
  ynetWorksitesMapperFn,
} from "./mapPropsToValues";
import { xor } from "./UserForm.envNetwork";
import { InnerFormWorksite } from "new-design/utils/types";
import { OmaltRole, YnetRole } from "new-design/utils/backendTypes";

type CustomerWorksitesProps = {
  worksites: (InnerFormYnetWorksite & InnerFormOmaltWorksite)[];
  setFieldValue: (field: string, value: any) => void;
  values: UserInnerFormValues;
  ymparistonettiRole: YnetRole;
  omaltRole: OmaltRole;
};

export const CustomerWorksites = ({
  worksites,
  values,
  setFieldValue,
  ymparistonettiRole,
  omaltRole,
}: CustomerWorksitesProps) => {
  const theme = useTheme();

  const allowYnetWorksiteSelect = useMemo(
    () => [YnetRole.Customer].includes(ymparistonettiRole),
    [ymparistonettiRole]
  );

  const allowOmaltWorksiteSelect = useMemo(
    () => [OmaltRole.Customer].includes(omaltRole),
    [omaltRole]
  );

  const handleYnetWorksiteChange = useCallback(
    (worksite: InnerFormWorksite) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(
          "ymparistonetti.customers",
          ynetCustomersMapperFn(
            values.ymparistonetti.customers,
            (customer) => customer.customer.id === worksite.customerId,
            (sites) => {
              return ynetSitesMapperFn(
                sites,
                (site) => site.site.id === worksite.siteId,
                (worksites) => {
                  return ynetWorksitesMapperFn(
                    worksites,
                    (w) => w.worksite.id === worksite.id,
                    (worksite) => {
                      return {
                        worksite: worksite.worksite,
                        config: {
                          isYnetSelected: event.target.checked,
                        },
                      };
                    }
                  );
                }
              );
            }
          )
        );
      },
    [setFieldValue, values.ymparistonetti.customers]
  );

  const handleOmaltWorksiteChange = useCallback(
    (worksite: InnerFormWorksite) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(
          "omalt.customers",
          omaltCustomersMapperFn(
            values.omalt.customers,
            (customer) => customer.customer.id === worksite.customerId,
            (sites) => {
              return omaltSitesMapperFn(
                sites,
                (site) => site.site.id === worksite.siteId,
                (worksites) => {
                  return omaltWorksitesMapperFn(
                    worksites,
                    (w) => w.worksite.id === worksite.id,
                    (worksite) => {
                      return {
                        worksite: worksite.worksite,
                        config: {
                          isOmaltSelected: event.target.checked,
                        },
                      };
                    }
                  );
                }
              );
            }
          )
        );
      },
    [setFieldValue, values.omalt.customers]
  );

  const handleWorksiteChange = useCallback(
    (worksite: InnerFormWorksite) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        handleYnetWorksiteChange(worksite)(event);
        handleOmaltWorksiteChange(worksite)(event);
      },
    [handleYnetWorksiteChange, handleOmaltWorksiteChange]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {worksites.map((worksite) => {
        return (
          <CustomerWorksiteItem key={worksite.worksite.id}>
            <InputCheckbox
              id={`ynet-and-omalt-worksite--${worksite.worksite.id}`}
              onChange={handleWorksiteChange(worksite.worksite)}
              indeterminate={xor(
                worksite.config.isYnetSelected,
                worksite.config.isOmaltSelected
              )}
              checked={
                worksite.config.isYnetSelected &&
                worksite.config.isOmaltSelected
              }
              label={
                <span
                  style={{
                    paddingLeft: "16px",
                    display: "flex",
                    fontSize: "16px",
                  }}
                >
                  <span style={{ display: "block", width: "220px" }}>
                    {worksite.worksite.address.street}
                    <br />
                    {worksite.worksite.id}
                  </span>
                  <span
                    style={{
                      display: "block",
                      width: "44%",
                      paddingRight: "24px",
                    }}
                  >
                    {worksite.worksite.name}
                  </span>
                  <span style={{ display: "block" }}>
                    {worksite.worksite.address.city}
                  </span>
                </span>
              }
              style={{ width: "100%" }}
              disabled={!(allowYnetWorksiteSelect && allowOmaltWorksiteSelect)}
            />
            <div style={{ display: "flex", alignItems: "center", gap: "88px" }}>
              <InputCheckbox
                id={`ymparistonetti-worksite--${worksite.worksite.id}`}
                onChange={handleYnetWorksiteChange(worksite.worksite)}
                checked={worksite.config.isYnetSelected}
                label={
                  <span style={{ paddingLeft: "14px" }}>
                    {theme.icons.ymparistonetti}
                  </span>
                }
                disabled={!allowYnetWorksiteSelect}
              />
              <InputCheckbox
                id={`omalt-worksite--${worksite.worksite.id}`}
                onChange={handleOmaltWorksiteChange(worksite.worksite)}
                checked={worksite.config.isOmaltSelected}
                label={
                  <span style={{ paddingLeft: "14px" }}>
                    {theme.icons.omalt}
                  </span>
                }
                disabled={!allowOmaltWorksiteSelect}
              />
            </div>
          </CustomerWorksiteItem>
        );
      })}
    </div>
  );
};
