import {
  EmailInstructions,
  KiinteistonettiSettingsConfig,
  OmaltSettingsConfig,
  RaksanappiSettingsConfig,
  YnetSettingsConfig,
} from "./types";
import {
  KiinteistonettiRole,
  OmaltRole,
  RaksanappiRole,
  YnetRole,
} from "./backendTypes";

export const ynetConfig: YnetSettingsConfig = {
  id: "ynet",
  emailOptions: [
    { label: "Uusi käyttäjä", value: EmailInstructions.NewUser },
    { label: "Olemassa oleva käyttäjä", value: EmailInstructions.ExistingUser },
  ],
  roles: [
    { value: YnetRole.NoAccess, label: "Ei oikeuksia" },
    { value: YnetRole.Customer, label: "Käyttäjä" },
    { value: YnetRole.Admin, label: "Pääkäyttäjä" },
  ],
  initialRole: YnetRole.NoAccess,
  initialPremiumSubscription: false,
};

export const omaltConfig: OmaltSettingsConfig = {
  id: "omalt",
  emailOptions: [
    { label: "Uusi käyttäjä", value: EmailInstructions.NewUser },
    { label: "Olemassa oleva käyttäjä", value: EmailInstructions.ExistingUser },
  ],
  roles: [
    { value: OmaltRole.NoAccess, label: "Ei oikeuksia" },
    { value: OmaltRole.Customer, label: "Käyttäjä" },
    { value: OmaltRole.Admin, label: "Pääkäyttäjä" },
  ],
  initialRole: OmaltRole.NoAccess,
  initialBillingAccess: false,
};

export const raksanappiConfig: RaksanappiSettingsConfig = {
  id: "raksanappi",
  emailOptions: [{ label: "Uusi käyttäjä", value: EmailInstructions.NewUser }],
  roles: [
    { value: RaksanappiRole.NoAccess, label: "Ei oikeuksia" },
    { value: RaksanappiRole.Customer, label: "Työmaakäyttäjä" },
  ],
  initialRole: RaksanappiRole.NoAccess,
  initialHasAccessToReports: false,
};

export const kiinteistonettiConfig: KiinteistonettiSettingsConfig = {
  id: "kiinteistonetti",
  emailOptions: [{ label: "Uusi käyttäjä", value: EmailInstructions.NewUser }],
  roles: [
    // { value: KiinteistonettiRole.Admin, label: 'Admin' },
    { value: KiinteistonettiRole.Customer, label: "Käyttäjä" },
    { value: KiinteistonettiRole.NotUser, label: "Ei oikeuksia" },
  ],
  initialRole: KiinteistonettiRole.NotUser,
};
