export const messagesFi = {
  "page-logo.alt": "L&T logo alt",

  "page-title": "Hallinnointityökalu",

  "new-user-page.form-title": "Uusi käyttäjä",
  "new-user-page.unknown-error-message":
    "Käyttäjän luonti epäonnistui, yritä vielä uudelleen. Mikäli ongelma jatkuu, laita asiasta Portti-tiketti.",
  "new-user-page.user-created-successfully-message":
    "Käyttäjän {email} luonti onnistui",
  "edit-user-page.form-title": "Muokkaa käyttäjä",
  "edit-user-page.unknown-error-message":
    "Käyttäjätietojen muokkaus epäonnistui, yritä vielä uudelleen. Mikäli ongelma jatkuu, laita asiasta Portti-tiketti.",
  "edit-user-page.user-updated-successfully-message":
    "Käyttäjän {email} päivitys onnistui.",
  "edit-user-page.loading": "Ladataan",
  "edit-user-page.retry": "Yritä uudelleen",

  "users-page.page-title": "Käyttäjähallinta",
  "users-page.user-tab": "Käyttäjät",
  "users-page.user-tab.name-header": "Nimi",
  "users-page.user-tab.email-header": "Sähköposti",
  "users-page.companies-tab": "Yritykset",
  "users-page.destinations-tab": "Kohteet",
  "users-page.search-placeholder": "Etsi käyttäjiä...",
  "users-page.invite-button": "Kutsu uusi käyttäjä",
  "users-page.services": "Palvelut",
  "users-page.pagination-label": "Käyttäjät",
  "users-page.filters-pane.filters": "Suodatus",
  "users-page.filters-pane.filters-on": "Suodatus: päällä",
  "users-page.filters-pane.clear-filters": "Tyhjennä",
  "users-page.filters-pane.omalt.admin": "Pääkäyttäjä",
  "users-page.filters-pane.omalt.customer": "Käyttäjä",
  "users-page.filters-pane.omalt.no-access": "Ei oikeuksia",
  "users-page.filters-pane.ynet.admin": "Pääkäyttäjä",
  "users-page.filters-pane.ynet.customer": "Käyttäjä",
  "users-page.filters-pane.ynet.no-access": "Ei oikeuksia",
  "users-page.filters-pane.ynet.paying-users": "Täysversio",
  "users-page.filters-pane.ynet.non-paying-users": "Ilmaisversio",
  "users-page.filters-pane.raksanappi.admin": "Työmaakäyttäjä",
  "users-page.filters-pane.raksanappi.none": "Ei oikeuksia",
  "users-page.filters-pane.kiinteistonetti.customer": "Käyttäjä",
  "users-page.filters-pane.kiinteistonetti.not-user": "Ei oikeuksia",
  "users-page.filters-pane.cancel-button": "Peruuta",
  "users-page.filters-pane.apply-filters": "Suodata",

  "user-form.back-button": "Takaisin",
  "user-form.create-user-button": "Luo käyttäjä",
  "user-form.edit-user-button": "Tallenna",

  "user-form.first-name": "Etunimi",
  "user-form.last-name": "Sukunimi",
  "user-form.email": "Sähköpostiosoite",
  "user-form.phone-number": "Puhelinnumero",
  "user-form.wrong-values-for-user":
    "Seuraaville kentille on annettu virheellisiä arvoja:",

  "user-form.environmental-network.tab-label": "Ympäristönetti & Oma L&T",
  "user-form.environmental-network.typeahead-loading-text": "Ladataan ...",

  "user-form.raksanappi-tab-label": "Raksanappi",

  "user-form.kiinteistonetti-tab-label": "Kiinteistönetti",
  "user-form.kiinteistonetti.user-access-level":
    "Kiinteistönetti käyttöoikeustaso",
  "user-form.kiinteistonetti.email-instructions-checkbox-label":
    "Lähetä ohjeet sähköpostiin",
  "user-form.kiinteistonetti.email-instructions-select-label": "Template",
  "user-form.kiinteistonetti.typeahead-loading-text": "Ladataan ...",

  "user-form.ymparistonetti.user-access-level":
    "Ympäristönetti käyttöoikeustaso",
  "user-form.ymparistonetti.premium-subscription-checkbox-label": "Täysversio",
  "user-form.ymparistonetti.email-instructions-checkbox-label":
    "Lähetä ohjeet sähköpostiin",
  "user-form.ymparistonetti.email-instructions-select-label": "Viestipohja",

  "user-form.omalt.user-access-level": "Oma L&T käyttöoikeustaso",
  "user-form.omalt.billing-role-checkbox-label": "Saa katsoa laskuja",
  "user-form.omalt.email-instructions-checkbox-label":
    "Lähetä ohjeet sähköpostiin",
  "user-form.omalt.email-instructions-select-label": "Viestipohja",

  "user-form.raksanappi.user-access-level": "Raksanappi käyttöoikeustaso",
  "user-form.raksanappi.email-instructions-checkbox-label":
    "Lähetä ohjeet sähköpostiin",
  "user-form.raksanappi.email-instructions-select-label": "Template",
  "user-form.raksanappi.typeahead-loading-text": "Ladataan ...",

  "user-form.validation.first-name.required": "Pakollinen kenttä",
  "user-form.validation.last-name.required": "Pakollinen kenttä",
  "user-form.validation.email.invalid": "Virheellinen sähköposti",
  "user-form.validation.email.required": "Pakollinen kenttä",
  "user-form.validation.email.user-already-exists": "Käyttäjä on jo olemassa",
  "user-form.validation.email.user-search-failed": "Käyttäjän haku epäonnistui",
  "user-form.validation.email.check-in-progress":
    "Sähköpostin tarkistaminen ...",
  "user-form.validation.phone-number.required": "Pakollinen kenttä",
  "user-form.validation.phone-number.invalid": "Virheellinen puhelinnumero",

  "user-form.customer-search.label": "Käyttöoikeudet asiakkaisiin",
  "user-form.customer-search.placeholder": "Hae kaupunki tai yritys...",
  "user-form.worksite-search.label": "Käyttöoikeudet kohteisiin",
  "user-form.worksite-search.placeholder": "Hae kohde, kaupunki tai yritys ...",

  "email-preview-image.alt": "Sähköpostin esikatselukuva",

  "user-settings.import-settings-from-another-user":
    "Tuo käyttöoikeusasetukset toiselta käyttäjältä",
  "user-settings.find-user-placeholder": "Etsi käyttäjä ...",
  "user-settings.typeahead-loading-text": "Ladataan ...",
  "user-settings.button-label": "Tuo",
  "user-settings.import-succeeded-status": "Valmis",
  "user-settings.import-succeeded-notification":
    "Käyttöoikeusasetukset tuotu onnistuuneesti.",
  "user-settings.import-failed-status": "Tuntematon virhe",
  "user-settings.import-failed-notification":
    "Käyttöoikeusasetusten tuonti epäonnistui, yritä vielä uudelleen.{br}Mikäli ongelma jatkuu, laita asiasta Portti-tiketti.",
};
