import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Header, Button } from "@kettu/ui-components";

import { PageTitle } from "new-design/components";
import { Row } from "new-design/styled";
import { UserForm, getValidationSchema } from "new-design/components/UserForm";
import {
  reducer as userReducer,
  initialState as userInitialState,
  fetchUser,
} from "./EditUserPage.user";
import { Container } from "../components/UserForm/styled";
import { useFormattedMessage } from "../utils/useFormattedMessage.hook";
import useAuth from "../../auth/useAuth.hook";
import { useVerifyEmail } from "new-design/utils/useVerifyEmail";

export const EditUserPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { id: userId = "" } = useParams();

  const intl = useIntl();

  const pageTitle = useFormattedMessage("page-title");
  const loadingText = useFormattedMessage("edit-user-page.loading");
  const retryText = useFormattedMessage("edit-user-page.retry");

  const handlePageTitleClick = useCallback(() => navigate("/admin/search"), []);
  const { emailExists } = useVerifyEmail();
  const validationSchema = useMemo(
    () => getValidationSchema(emailExists)(intl),
    []
  );

  const [userState, userDispatcher] = useReducer(userReducer, userInitialState);
  const fetch = useCallback(
    () => fetchUser({ dispatch: userDispatcher, userId }),
    [userDispatcher, userId]
  );
  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Header height="66px">
        <Row gap="14px">
          <PageTitle title={pageTitle} onClick={handlePageTitleClick} />
        </Row>
      </Header>
      {userState.user ? (
        <UserForm
          user={userState.user}
          navigate={navigate}
          validationSchema={validationSchema}
          actorId={auth.actorId as string}
        />
      ) : userState.loading ? (
        <Container>{loadingText}</Container>
      ) : (
        (userState.errors || []).length > 0 && (
          <Container>
            <Button type="button" onClick={() => fetch()}>
              {retryText}
            </Button>
          </Container>
        )
      )}
    </>
  );
};
