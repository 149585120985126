import {
  YnetRole,
  OmaltRole,
  RaksanappiRole,
  KiinteistonettiRole,
  YnetConfig,
  OmaLtConfig,
  RaksanappiResponseData,
  KiinteistonettiResponseData,
  UserPersonalInformation,
  RaksanappiConfig,
} from "./backendTypes";

export enum EmailInstructions {
  NewUser = "new",
  ExistingUser = "old",
}

export type EmailOption = {
  label: string;
  value: EmailInstructions;
};

export type ServiceSettingsConfig = {
  emailOptions: EmailOption[];
  id: string;
};

export type YnetSettingsConfig = ServiceSettingsConfig & {
  roles: { value: YnetRole; label: string }[];
  initialRole: YnetRole;
  initialPremiumSubscription: boolean;
};

export type OmaltSettingsConfig = ServiceSettingsConfig & {
  roles: { value: OmaltRole; label: string }[];
  initialRole: OmaltRole;
  initialBillingAccess: boolean;
};

export type RaksanappiSettingsConfig = ServiceSettingsConfig & {
  roles: { value: RaksanappiRole; label: string }[];
  initialRole: RaksanappiRole;
  initialHasAccessToReports: boolean;
};

export type KiinteistonettiSettingsConfig = ServiceSettingsConfig & {
  roles: { value: KiinteistonettiRole; label: string }[];
  initialRole: KiinteistonettiRole;
};

export type PersonalInfo = {
  firstName: string;
  lastName: string;
  phone: string | undefined;
  email: string;
};

export type CustomerBase = {
  id: string;
  name: string;
};

export type Customer = {
  id: string;
  name: string;
  name1: string;
  accountGroup: string;
  vat: string;
  lineOfBusiness: string;
  language: string;
  customerStatus: string;
  streetName: string;
  houseNumber: string;
  postCode: string;
  city: string;
  country: string;
  currency: string;
  postingGroup: string;
  sites: Site[];
} & CustomerBase;

export type InnerFormWorksite = Omit<
  Worksite & {
    siteId: string;
    siteName: string;
    customerId: string;
    customerName: string;
  },
  "site"
>;

export type Site = {
  id: string;
  name: string;
  worksites: InnerFormWorksite[];
};

export type WorksiteSite = {
  id: string;
  name: string;
  customer: CustomerBase;
};

export type WorksiteAddress = {
  street: string;
  postcode: string;
  city: string;
  country: string;
};

export type Worksite = {
  id: string;
  name1: string;
  name2: string;
  name: string;
  activity: string;
  address: WorksiteAddress;
  site: WorksiteSite;
};

export type ServiceSettings = {
  customerships: Customer[];
  sendNewUserWelcomeEmail?: boolean;
};

export type YnetSettings = ServiceSettings & {
  role: YnetRole;
  premium: boolean;
  sendExistingUserWelcomeEmail?: boolean;
};

export type OmaLtSettings = ServiceSettings & {
  role: OmaltRole;
  billingAccess: boolean;
  sendExistingUserWelcomeEmail?: boolean;
  phone?: string;
  termsAccepted?: Date;
};

export type RaksanappiSettings = ServiceSettings & {
  role: RaksanappiRole;
  terms?: Date;
  worksites: Worksite[];
};

export type User = {
  id: string;
  personalInformation: UserPersonalInformation;
  ynet: YnetConfig & { worksites: Worksite[] };
  omalt: OmaLtConfig & { worksites: Worksite[] };
  raksanappi: RaksanappiConfig & { worksites: Worksite[] };
  kiinteistonetti: KiinteistonettiResponseData;
};
