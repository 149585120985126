import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import {
  withFormik,
  Form,
  ErrorMessage,
  FormikProps,
  FieldMetaProps,
} from "formik";
import {
  Tabs,
  Tab,
  TabContent,
  Button,
  ButtonVariant,
  Title,
  Alert,
  AlertType,
  Icon,
  ThemeType,
} from "@kettu/ui-components";
import { post } from "utils/api";
import { Modal } from "../Modal";
import { EmailPreview } from "../EmailPreview";
import { Customer, EmailInstructions, User } from "new-design/utils/types";
import {
  UserConfig,
  YnetRole,
  OmaltRole,
  RaksanappiRole,
  KiinteistonettiRole,
} from "new-design/utils/backendTypes";
import {
  mapPropsToValues,
  mapGetUserResponseToUserInnerFormValues,
  InnerFormOmaltCustomers,
  InnerFormYnetCustomers,
  InnerFormRaksanappiCustomers,
} from "./mapPropsToValues";
import { getModifyUserPayload } from "./getModifyUserPayload";
import {
  Container,
  UserFormHeader,
  NavigationButtonsWrapper,
  HorizontalRule,
  CustomErrorMessage,
  UserAlertBanner,
  NewUserCreatedAlertBannerlabel,
  UserUpdatedAlertBannerLabel,
  UserErrorAlertBannerLabel,
} from "./styled";
import {
  userFormTranslations,
  UserFormTranslationsType,
} from "./UserForm.translations";
import { useTranslation } from "../../utils/useTranslation";
import {
  isEmailFormatInvalid,
  UserFormPersonalInfo,
} from "./UserForm.personalInfo";
import { UserFormEnvNetwork } from "./UserForm.envNetwork";
import { UserFormRaksanappi } from "./UserForm.raksanappi";
import { UserSettings } from "../UserSettings";
import { UserFormKiinteistonetti } from "./UserForm.kiinteistonetti";

import { IAM3_KIINTEISTONETTI_ENABLED } from "featureFlags";
import { scrollIntoView } from "../../utils/scrollintoView";

export type UserInnerFormProps = {
  actorId: string;
  navigate: NavigateFunction;
  validationSchema: Yup.ObjectSchema<any>;
};

export type UserInnerFormValues = {
  auth0Id?: string;
  personalInfo: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    isDemoUser: boolean;
  };
  ymparistonetti: {
    customers: InnerFormYnetCustomers;
    emailInstructions: EmailInstructions;
    premiumSubscription: boolean;
    role: YnetRole;
    sendEmailInstructions: boolean;
    termsAccepted: string | undefined;
    webNotifications: boolean;
  };
  omalt: {
    customers: InnerFormOmaltCustomers;
    emailInstructions: EmailInstructions;
    billingAccess: boolean;
    role: OmaltRole;
    sendEmailInstructions: boolean;
    termsAccepted?: string;
    hasSeenIntroductionPopUp: boolean;
  };
  raksanappi: {
    customers: InnerFormRaksanappiCustomers;
    emailInstructions: EmailInstructions;
    role: RaksanappiRole;
    sendEmailInstructions: boolean;
    terms?: Date;
    hasAccessToReports: boolean;
  };
  kiinteistonetti: {
    emailInstructions: EmailInstructions;
    sendEmailInstructions: boolean;
    customers: Customer[];
    role: KiinteistonettiRole;
  };
};

const getInputFieldAdditionalProps = (
  name: string,
  meta: FieldMetaProps<any>,
  messageComponent: any = CustomErrorMessage
) => {
  return {
    message: <ErrorMessage component={messageComponent} name={name} />,
    valid: meta.touched && !meta.error,
    invalid: meta.touched && !!meta.error,
  };
};

export const UserInnerForm = (
  props: UserInnerFormProps & FormikProps<UserInnerFormValues>
) => {
  const { values } = props;
  const intl = useIntl();
  const theme = useTheme() as ThemeType;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const translations = useTranslation(
    userFormTranslations
  ) as UserFormTranslationsType;
  const [activeTab, setActiveTab] = React.useState(0);
  const [emailPreviewElement, setEmailPreviewElement] = React.useState<any>();
  const isNewUser = searchParams.has("new-user");
  const isExistingUser = useMemo(
    () => (values.auth0Id || "").startsWith("auth0"),
    [values.auth0Id]
  );
  const oldSubmitCount = useRef(props.submitCount);

  const handlePreviewEmailTemplateClick = useCallback(
    (serviceId: string, welcomeMessageType: EmailInstructions) => () => {
      setEmailPreviewElement(
        <EmailPreview
          service={serviceId}
          welcomeMessageType={welcomeMessageType}
        />
      );
    },
    []
  );

  const renderButtons = useCallback(() => {
    return (
      <NavigationButtonsWrapper>
        <Button
          type="button"
          variant={ButtonVariant.Secondary}
          icon={
            <Icon
              icon={theme.icons.arrowLeft}
              size={{ width: "12px", height: "18px" }}
            />
          }
          label={translations.backButtonText}
          onClick={() => navigate("/admin/search")}
        />
        <Button
          type="submit"
          label={
            isExistingUser
              ? translations.editUserButtonText
              : translations.createUserButtonText
          }
          icon={
            isExistingUser ? (
              <Icon
                icon={theme.icons.check}
                size={{ width: "18px", height: "18px" }}
              />
            ) : undefined
          }
          disabled={props.isSubmitting || (isExistingUser && !props.dirty)}
        />
      </NavigationButtonsWrapper>
    );
  }, [props.isSubmitting, props.dirty]);

  const handleUserSettingsCallback = useCallback(
    (userGetResponse) => {
      const { auth0Id, personalInfo, ...userSettings } =
        mapGetUserResponseToUserInnerFormValues(userGetResponse);
      const userSettingsWithPersonalInfoPersisted = {
        ...userSettings,
        auth0Id: values.auth0Id,
        personalInfo: values.personalInfo,
      };
      props.setValues(userSettingsWithPersonalInfoPersisted, false);
    },
    [props.setValues, values.auth0Id, values.personalInfo]
  );

  const handleErrorAlertBannerClose = useCallback(
    () => props.setStatus({ errors: false }),
    [props.setStatus]
  );
  const handleCreationErrorAlertBannerClose = useCallback(() => {
    props.setErrors({});
  }, [props.setErrors]);

  const userErrorAlertBanner = useCallback(
    (isExistingUser) => (
      <UserAlertBanner>
        <Alert
          onCloseClick={handleErrorAlertBannerClose}
          showCloseButton={true}
          alertType={AlertType.Danger}
          label={
            <UserErrorAlertBannerLabel>
              <Icon
                icon={theme.icons.messageIconWarning}
                size={{ height: "20px", width: "20px" }}
              />
              <span>
                {isExistingUser
                  ? translations.editUserUnknownErrorMessage
                  : translations.newUserUnknownErrorMessage}
              </span>
            </UserErrorAlertBannerLabel>
          }
        />
      </UserAlertBanner>
    ),
    [handleErrorAlertBannerClose]
  );

  const userCreationFailedBanner = useCallback(() => {
    const errorFields = Object.keys(props.errors.personalInfo || {})
      .map((name) => ({
        name,
        meta: props.getFieldMeta(`personalInfo.${name}`),
      }))
      .filter(
        ({ meta }) =>
          meta.touched && meta.error && isEmailFormatInvalid(meta.error)
      )
      .map(({ name }) => name);

    return (
      <UserAlertBanner id="user-creation-failed-alert-banner">
        {errorFields.length > 0 && (
          <Alert
            onCloseClick={handleCreationErrorAlertBannerClose}
            showCloseButton={true}
            alertType={AlertType.Danger}
            label={
              <UserErrorAlertBannerLabel>
                <Icon
                  icon={theme.icons.messageIconWarning}
                  size={{ height: "20px", width: "20px" }}
                />
                <span>
                  {translations.wrongValuesForUser}{" "}
                  {errorFields
                    .map((field) => (translations as any)[`${field}Label`])
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </UserErrorAlertBannerLabel>
            }
          />
        )}
      </UserAlertBanner>
    );
  }, [
    handleCreationErrorAlertBannerClose,
    props.errors,
    props.errors.personalInfo,
    props.touched,
    props.touched.personalInfo,
  ]);

  const handleNewUserAlertBannerClick = useCallback(() => {
    const newQueryParameters: URLSearchParams = new URLSearchParams();
    newQueryParameters.delete("new-user");
    setSearchParams(newQueryParameters);
  }, [setSearchParams]);

  const newUserCreatedAlertBanner = useMemo(() => {
    const messageId = "new-user-page.user-created-successfully-message";
    const message = intl.formatMessage(
      { id: messageId },
      { email: <b>{values.personalInfo.email}</b> }
    );
    return (
      <UserAlertBanner>
        <Alert
          onCloseClick={handleNewUserAlertBannerClick}
          showCloseButton={true}
          alertType={AlertType.Success}
          label={
            <NewUserCreatedAlertBannerlabel>
              <Icon
                icon={theme.icons.checkCircleOval}
                size={{ height: "22px", width: "22px" }}
              />
              <span>{message}</span>
            </NewUserCreatedAlertBannerlabel>
          }
        />
      </UserAlertBanner>
    );
  }, [handleNewUserAlertBannerClick]);

  const handleUserUpdatedSuccessAlertBannerClick = useCallback(() => {
    props.setStatus({ ...(props.status || {}), updated: false });
  }, [props.setStatus, props.status]);

  const userUpdatedSuccessAlertBanner = useMemo(() => {
    const messageId = "edit-user-page.user-updated-successfully-message";
    const message = intl.formatMessage(
      { id: messageId },
      { email: <b>{values.personalInfo.email}</b> }
    );
    return (
      <UserAlertBanner id="user-updated-success-alert-banner-wrapper">
        <Alert
          onCloseClick={handleUserUpdatedSuccessAlertBannerClick}
          showCloseButton={true}
          alertType={AlertType.Success}
          label={
            <UserUpdatedAlertBannerLabel>
              <Icon
                icon={theme.icons.checkCircleOval}
                size={{ height: "22px", width: "22px" }}
              />
              <span>{message}</span>
            </UserUpdatedAlertBannerLabel>
          }
        />
      </UserAlertBanner>
    );
  }, [handleUserUpdatedSuccessAlertBannerClick, values.personalInfo.email]);

  useEffect(() => {
    if (props.status?.updated) {
      scrollIntoView("user-updated-success-alert-banner-wrapper");
    }
  }, [props.status?.updated]);

  useEffect(() => {
    if (oldSubmitCount.current !== props.submitCount) {
      oldSubmitCount.current = props.submitCount;
      scrollIntoView("user-creation-failed-alert-banner");
    }
  }, [props.submitCount]);

  return (
    <Form noValidate>
      <Container>
        <UserFormHeader>
          <Title
            text={
              isExistingUser
                ? translations.editUserFormTitle
                : translations.newUserFormTitle
            }
          />
          {renderButtons()}
        </UserFormHeader>
        {props.status?.errors && userErrorAlertBanner(isExistingUser)}
        {userCreationFailedBanner()}
        {isNewUser && newUserCreatedAlertBanner}
        {props.status?.updated && userUpdatedSuccessAlertBanner}
        <HorizontalRule />
        <UserFormPersonalInfo
          validationSchema={props.validationSchema}
          translations={translations}
          values={props.values}
          getInputFieldAdditionalProps={getInputFieldAdditionalProps}
        />
        <div>
          <Tabs onChange={setActiveTab}>
            <Tab label={translations.ymparistonettiAndOmaLtLabel} />
            <Tab label={translations.raksanappiLabel} />
            {IAM3_KIINTEISTONETTI_ENABLED ? (
              <Tab label={translations.kiinteistonettiLabel} />
            ) : (
              <Tab label="" />
            )}
          </Tabs>
          <TabContent value={activeTab} index={0}>
            <div style={{ marginTop: "18px" }}>
              <UserSettings
                onUserSettingsCallback={handleUserSettingsCallback}
              />
            </div>
            <UserFormEnvNetwork
              translations={translations}
              values={values}
              setFieldValue={props.setFieldValue}
              handlePreviewEmailTemplateClick={handlePreviewEmailTemplateClick}
              setEmailPreviewElement={setEmailPreviewElement}
            />
          </TabContent>
          <TabContent value={activeTab} index={1}>
            <div style={{ marginTop: "18px" }}>
              <UserSettings
                onUserSettingsCallback={handleUserSettingsCallback}
              />
            </div>
            <UserFormRaksanappi
              translations={translations}
              setFieldValue={props.setFieldValue}
              values={values}
              handlePreviewEmailTemplateClick={handlePreviewEmailTemplateClick}
            />
          </TabContent>
          {IAM3_KIINTEISTONETTI_ENABLED && (
            <TabContent value={activeTab} index={2}>
              <UserFormKiinteistonetti
                translations={translations}
                values={props.values}
                setFieldValue={props.setFieldValue}
                handlePreviewEmailTemplateClick={
                  handlePreviewEmailTemplateClick
                }
                setEmailPreviewElement={setEmailPreviewElement}
              />
            </TabContent>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {renderButtons()}
        </div>
      </Container>
      <Modal
        handleClose={() => setEmailPreviewElement(undefined)}
        isOpen={!!emailPreviewElement}
      >
        {emailPreviewElement}
      </Modal>
    </Form>
  );
};

export type UserFormProps = {
  user: null | User;
};

export const UserForm = withFormik<
  UserFormProps & UserInnerFormProps,
  UserInnerFormValues
>({
  displayName: "UserForm",
  handleSubmit: async (values, actions) => {
    const payload = getModifyUserPayload(values, actions.props.actorId);
    if ((payload.auth0Id || "").startsWith("auth0")) {
      const response = await post("/users/update", payload);

      if (!response.ok) {
        actions.setStatus({ errors: true, updated: false });
        actions.setSubmitting(false);
        return;
      }

      if (response.status >= 400) {
        actions.setStatus({ errors: true, updated: false });
      } else {
        actions.setStatus({ errors: false, updated: true });
      }
      actions.setSubmitting(false);
    } else {
      const response = await post("/users/create", payload);

      if (!response.ok) {
        actions.setStatus({ errors: true });
        actions.setSubmitting(false);
        return;
      }

      const user = (await response.json()) as UserConfig;
      if (response.status >= 400) {
        actions.setStatus({ errors: true });
      } else {
        actions.setStatus({ errors: false });
        actions.props.navigate(`/admin/user/${user.userId}?new-user`);
      }
      actions.setSubmitting(false);
    }
  },
  mapPropsToValues,
  validationSchema: (props: UserFormProps & UserInnerFormProps) =>
    props.validationSchema,
  validateOnChange: false,
})(UserInnerForm);
