import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.background.white};
  margin: 38px auto;
  max-width: 1150px;
  padding: ${(props) => props.theme.spacings.spacing9};
`;

export const UserFormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacings.spacing6};
`;

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.spacing4};
`;

export const HorizontalRule = styled.hr`
  border: 0;
  border-top: ${(props) => `1px solid ${props.theme.background.dark}`};
  display: block;
  height: 0;
  margin: 0;
  padding: 0;
`;

export const InputCheckboxLabel = styled.span`
  display: inline-block;
  padding-left: 8px;
`;

export const CustomErrorMessage = styled.div`
  color: ${(props) => props.theme.semanticColors.danger.darkAlt};
  font-family: ${(props) => props.theme.typography.secondaryFont.family};
  font-size: 14px;
  margin-top: 6px;
`;

export const CustomInfoMessage = styled.div`
  color: ${(props) => props.theme.text.black};
  font-family: ${(props) => props.theme.typography.secondaryFont.family};
  font-size: 14px;
  margin-top: 6px;
`;

export const PreviewEmailTemplateButton = styled.button.attrs({
  type: "button",
})`
  background-color: ${(props) => props.theme.background.white};
  border: ${(props) => `1px solid ${props.theme.background.grey}`};
  border-radius: 50%;
  color: ${(props) => props.theme.background.grey};
  cursor: pointer;
  height: 40px;
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  width: 40px;
`;

export const TypeaheadLabel = styled.label`
  display: block;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 24px;
`;

export const CustomerListHeader = styled.header`
  display: flex;
  font-family: ${(props) => props.theme.typography.secondaryFont.family};
  justify-content: end;
  padding: 15px 0;
  text-align: center;
`;

export const CustomerItem = styled.div`
  border-bottom: ${(props) => `1px solid ${props.theme.background.dark}`};
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;
`;

export const CustomerWorksiteItem = styled.div`
  border-bottom: ${(props) => `1px solid ${props.theme.background.dark}`};
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 60px;
`;

export const CustomerList = styled.div`
  padding: 36px 0 40px 0;

  > ${CustomerItem}:first-of-type {
    border-top: ${(props) => `1px solid ${props.theme.background.dark}`};
  }
`;

export const CustomerWorksiteExpandButton = styled.div`
  color: ${(props) => props.theme.colors.default};
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-left: ${(props) => props.theme.spacings.spacing4};
`;

export const UserAlertBanner = styled.div`
  padding-bottom: ${(props) => props.theme.spacings.spacing6};
`;

export const NewUserCreatedAlertBannerlabel = styled.div`
  align-items: center;
  display: flex;
  gap: ${(props) => props.theme.spacings.spacing5};

  svg {
    color: ${(props) => props.theme.semanticColors.success.dark};
  }
`;

export const UserUpdatedAlertBannerLabel = styled.div`
  align-items: center;
  display: flex;
  gap: ${(props) => props.theme.spacings.spacing5};

  svg {
    color: ${(props) => props.theme.semanticColors.success.dark};
  }
`;

export const UserErrorAlertBannerLabel = styled.div`
  align-items: center;
  display: flex;
  gap: ${(props) => props.theme.spacings.spacing5};
`;
