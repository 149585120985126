import { FormEvent, useState } from "react";
import { post } from "utils/api";
import { emailValid } from "utils/validate";
import Footer from "components/Footer";

const UnblockUser = () => {
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (emailValid(email)) {
      setIsEmailValid(true);

      const params = new URLSearchParams(window.location.search);
      post("/passwords/unblock", {
        email: email,
        service: params.get("service"),
      }).then(() => {
        setSubmitted(true);
      });
    } else {
      setIsEmailValid(false);
    }
  };

  const unblockContent = () => (
    <>
      <h1>Tunnuksen aktivointi</h1>
      <p>
        Syötä sähköpostiosoite, niin aktivoimme tunnuksesi uudelleen. Lähetämme
        sinulle sähköpostia, kun tunnuksesi on aktivoitu.
      </p>
      <form className="password-form" noValidate={true} onSubmit={onSubmit}>
        <label htmlFor="email">Sähköposti</label>
        <div className="field">
          <input
            type="email"
            id="email"
            autoFocus={true}
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          {!isEmailValid && (
            <div className="validation-errors">Virheellinen sähköposti</div>
          )}
        </div>
        <button type="submit">Lähetä</button>
      </form>
    </>
  );

  const submittedContent = () => (
    <div className="success-container">
      <div className="success-icon" />
      <h1 className="success-title">Viesti lähetetty</h1>
      <p>
        Saat sähköpostiisi linkin, jolla voit aktivoida tunnuksesi uudelleen.
      </p>
    </div>
  );

  return (
    <div className="page">
      <div className="header" />
      <div className="content">
        <div className="box">
          {submitted ? submittedContent() : unblockContent()}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UnblockUser;
