import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LoginStatus } from "./types";
import useAuth from "./useAuth.hook";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();

  React.useEffect(() => auth.initialize(), []);

  if (auth.loginStatus === LoginStatus.LOADING) {
    return <>Logging in...</>;
  }

  if (auth.loginStatus === LoginStatus.LOGIN) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
