import { GetUserResponse } from "new-design/utils/backendTypes";
import { get } from "utils/api";
import { CustomerBase, User, Worksite } from "new-design/utils/types";
import _ from "lodash";

async function fetchUserConfig(userId: string) {
  const response = await get(`/users/${userId}`);
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || response.statusText);
  }

  const getUserResponse = (await response.json()) as GetUserResponse;
  return getUserResponse;
}

async function fetchWorksites(customerId: CustomerBase["id"]) {
  const response = await get(`/customers/${customerId}/worksites`);

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || response.statusText);
  }

  const worksites = (await response.json()) as Worksite[];
  return worksites;
}

const fetchWorksitesInBatches = async (
  batches: string[][]
): Promise<Worksite[]> => {
  const worksites: Worksite[] = [];
  for (const batch in batches) {
    try {
      //@ts-ignore
      worksites.push(await Promise.all(batches[batch].map(fetchWorksites)));
    } catch (e) {
      console.log(e);
    }
  }
  return _.flatten(worksites);
};

export enum FetchUserActionType {
  Request = "request",
  Success = "success",
  Failure = "failure",
  Reset = "reset",
}

type FetchUserAction = {
  type: FetchUserActionType;
  user?: User;
  errors?: string[];
};

export type FetchUserState = {
  loading: boolean;
  errors: string[];
  user: null | User;
};

export const initialState: FetchUserState = {
  loading: false,
  errors: [],
  user: null,
};

export const reducer = (
  state: FetchUserState,
  action: FetchUserAction
): FetchUserState => {
  const newState = {
    [FetchUserActionType.Request]: {
      loading: true,
    },
    [FetchUserActionType.Success]: {
      loading: false,
      user: action.user,
    },
    [FetchUserActionType.Failure]: {
      loading: false,
      errors: action.errors,
    },
    [FetchUserActionType.Reset]: initialState,
  };

  return Object.assign({}, state, newState[action.type]);
};

type fetchUserProps = {
  userId: string;
  dispatch: React.Dispatch<FetchUserAction>;
};

export const fetchUser = _.debounce(async (props: fetchUserProps) => {
  const { userId, dispatch } = props;

  dispatch({ type: FetchUserActionType.Request });
  try {
    const getUserResponse = await fetchUserConfig(userId);

    const ynetCustomers = [
      ...getUserResponse.ynet.fullAccessCustomers,
      ...getUserResponse.ynet.partialAccessCustomers.map(
        (partialAccessCustomer) => partialAccessCustomer.customer
      ),
    ];
    const omaltCustomers = [
      ...getUserResponse.omalt.fullAccessCustomers,
      ...getUserResponse.omalt.partialAccessCustomers.map(
        (partialAccessCustomer) => partialAccessCustomer.customer
      ),
    ];
    const raksanappiCustomers = [
      ...(getUserResponse.raksanappi.partialAccessCustomers?.map(
        (customer) => customer.customer
      ) || []),
    ];
    const customers = _.uniq([
      ...ynetCustomers,
      ...omaltCustomers,
      ...raksanappiCustomers,
    ]);
    const worksitesResults = await fetchWorksitesInBatches(
        _.chunk(customers, 256)
    );
    const worksites = worksitesResults.flatMap(
      (ynetWorksitesResult) => ynetWorksitesResult
    );

    const user: User = {
      ...getUserResponse,
      ynet: {
        ...getUserResponse.ynet,
        worksites: worksites.filter((worksite) =>
          ynetCustomers.includes(worksite.site.customer.id)
        ),
      },
      omalt: {
        ...getUserResponse.omalt,
        worksites: worksites.filter((worksite) =>
          omaltCustomers.includes(worksite.site.customer.id)
        ),
      },
      raksanappi: {
        ...getUserResponse.raksanappi,
        worksites: worksites.filter((worksite) =>
          raksanappiCustomers.includes(worksite.site.customer.id)
        ),
      },
    };

    dispatch({ type: FetchUserActionType.Success, user });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({
        type: FetchUserActionType.Failure,
        errors: [error.message],
      });
    }
  }
}, 500);
