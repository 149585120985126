import styled from "styled-components";

export const Navigation = styled.div`
  padding: ${(props) => `0 ${props.theme.spacings.spacing9}`};
`;

export const TabClone = styled.button`
  background: transparent;
  border: none;
  border-bottom: ${(props) => `2px solid ${props.theme.colors.default}`};
  color: ${(props) => props.theme.colors.default};
  font-family: ${(props) => props.theme.typography.mainFont.family};
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 20px 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const Search = styled.div`
  padding: ${(props) => props.theme.spacings.spacing9};
`;

export const SearchNavigation = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.spacing9};
  margin-bottom: ${(props) => props.theme.spacings.spacing9};
`;

export const ClearFiltersButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.default};
  cursor: pointer;
  display: flex;
  font-family: ${(props) => props.theme.typography.secondaryFont.family};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  gap: 4px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  padding: ${(props) => {
    const { spacing4, spacing5 } = props.theme.spacings;
    return `${spacing4} ${spacing5}`;
  }};
`;

export const SearchNavigationWithFilters = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacings.spacing5};
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacings.spacing5};
`;

export const YnetPremiumSign = styled.span`
  font-family: ${(props) => props.theme.typography.secondaryFont.family};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

export const PaginationContainer = styled.div`
  padding: ${(props) =>
    `0 ${props.theme.spacings.spacing9} ${props.theme.spacings.spacing9}`};
`;
