import { useLocation } from "react-router-dom";
import { LocationState } from "auth/types";

const LoginPage = () => {
  const location = useLocation();

  const state = location.state as LocationState;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const frontendUrl = window.location.origin;
  const from = state?.from?.pathname || "/";

  window.location.replace(
    backendUrl +
      "/auth/sso/init?redirectUrl=" +
      encodeURIComponent(frontendUrl) +
      encodeURIComponent(from)
  );
  return null;
};

export default LoginPage;
