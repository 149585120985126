import React from "react";
import { LoginStatus } from "auth/types";

interface AuthContextType {
  actorId: string | undefined;
  loginStatus: LoginStatus;
  initialize: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export default AuthContext;
