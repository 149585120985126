import React, { FormEvent, useState } from "react";
import { post } from "utils/api";
import Footer from "components/Footer";

import "./Password.sass";

const emailSubmittedContent = (email: string) => (
  <div className="success-container">
    <div className="success-icon" />
    <h1 className="success-title">Viesti lähetetty</h1>
    <p>Lähetimme linkin salasanan asettamiseen osoitteeseen {email}</p>
  </div>
);

const OrderPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const onSubmitOrder = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const redirectQuery: string | null = new URLSearchParams(
      window.location.search
    ).get("redirectUrl");
    const redirectUrl = redirectQuery ? redirectQuery : "https://lt.fi";
    post("/passwords/reset", { email: email, redirectUrl: redirectUrl }).then(
      (resp) => {
        if (resp.status === 200) {
          setSubmitted(true);
        } else {
          console.log("Failed.");
        }
      }
    );
  };
  return (
    <div className="page">
      <div className="header" />
      <div className="box">
        {submitted ? (
          emailSubmittedContent(email)
        ) : (
          <>
            <h1>Aseta uusi salasana tai palauta unohtunut salasana</h1>
            <p>
              Syötä sähköpostiosoitteesi johon voimme lähettää salasanan
              asetuslinkin.
            </p>
            <form
              className="password-form"
              noValidate={true}
              onSubmit={onSubmitOrder}
            >
              <label htmlFor="email">Sähköposti</label>
              <div className="field">
                <input
                  type="email"
                  id="email"
                  autoFocus={true}
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
              <button type="submit">Lähetä</button>
            </form>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default OrderPassword;
