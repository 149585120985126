import React, { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Header } from "@kettu/ui-components";
import { PageTitle } from "new-design/components";
import { Row } from "new-design/styled";
import { UserForm, getValidationSchema } from "new-design/components/UserForm";
import useAuth from "auth/useAuth.hook";
import { useVerifyEmail } from "new-design/utils/useVerifyEmail";

export const CreateUserPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();
  const { emailExists } = useVerifyEmail();
  const pageTitle = useMemo(() => intl.formatMessage({ id: "page-title" }), []);
  const handlePageTitleClick = useCallback(() => navigate("/admin/search"), []);

  const validationSchema = getValidationSchema(emailExists)(intl, true);

  return (
    <>
      <Header height="66px">
        <Row gap="14px">
          <PageTitle title={pageTitle} onClick={handlePageTitleClick} />
        </Row>
      </Header>
      <UserForm
        user={null}
        navigate={navigate}
        validationSchema={validationSchema}
        actorId={auth.actorId as string}
      />
    </>
  );
};
